import { Component, OnInit } from '@angular/core';
import { LanguageService } from './../../../core/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { MenuService } from 'src/app/protected/services/menu/menu.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  //variables que almacenan la imagenes
  fileImagenLogoFooter: string = "";
  fileImagenLogoCoFooter: string = "";
  fileIconTwitter: string = "";
  fileIconFacebook: string = "";
  fileIconYoutube: string = "";
  fileIconLinkedin: string = "";
  fileIconInstagram: string = "";

  constructor(private translate: TranslateService, private lang: LanguageService, private menuService: MenuService) {
  }

  ngOnInit(): void {
    this.translate.setDefaultLang(this.lang.browserLanguage());
    this.initCargaLogosImagenes();
  }

  /**
 * get imagenes y logos actualizados
 * 
 */
  private initCargaLogosImagenes() {
    this.menuService.getImagenesYLogosPorParametros("IMAGEN_REMATES_ACTUALIZADOS", "logo_header").subscribe((data) => {
      this.fileImagenLogoFooter = data;
    });
    this.menuService.getImagenesYLogosPorParametros("IMAGEN_REMATES_ACTUALIZADOS", "logo_co_footer").subscribe((data) => {
      this.fileImagenLogoCoFooter = data;
    });
    this.menuService.getImagenesYLogosPorParametros("IMAGEN_REMATES_ACTUALIZADOS", "twitter-icon").subscribe((data) => {
      this.fileIconTwitter = data;
    });
    this.menuService.getImagenesYLogosPorParametros("IMAGEN_REMATES_ACTUALIZADOS", "youtube-icon").subscribe((data) => {
      this.fileIconYoutube = data;
    });
    this.menuService.getImagenesYLogosPorParametros("IMAGEN_REMATES_ACTUALIZADOS", "linkedin-icon").subscribe((data) => {
      this.fileIconLinkedin = data;
    });
    this.menuService.getImagenesYLogosPorParametros("IMAGEN_REMATES_ACTUALIZADOS", "instagram-icon").subscribe((data) => {
      this.fileIconInstagram = data;
    });
    this.menuService.getImagenesYLogosPorParametros("IMAGEN_REMATES_ACTUALIZADOS", "facebook-icon").subscribe((data) => {
      this.fileIconFacebook = data;
    });

  }

}
