import {ValidarInputDirective} from './validar-input.directive';
import {ConvertirTextoDirective} from './convertir-texto.directive';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LongitudTextoDirective} from './longitud-input.directive';
import {CurrencyDirective} from "./currency.directive";
import {CoordinatesDirective} from "./coordinates.directive";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ValidarInputDirective,
        ConvertirTextoDirective,
        LongitudTextoDirective,
        CurrencyDirective,
        CoordinatesDirective
    ],
    exports: [
        ValidarInputDirective,
        ConvertirTextoDirective,
        LongitudTextoDirective,
        CurrencyDirective,
        CoordinatesDirective
    ]
})
export class DirectivesModule {
}
