<app-footer-info></app-footer-info>

<footer class="he_footer">
  <div class="container ">
    <div class="row">
      <div
        class="align-items-center align-items-md-start col-12 col-md-4 d-flex flex-column order-2 order-md-0 p-3 p-md-4">
        <!--imagen logo footer-->
        <a href="https://www.gov.co">
          <img [src]="fileImagenLogoFooter" alt="Imagen-footer"  class="logo-footer-co" />
        </a>
        <!--imagen logo footerCo -->
        <a href="https://www.colombia.co" class="mt-3">
          <img [src]="fileImagenLogoCoFooter" alt="Imagen-footer-co"  class="logo-footer-co" />
        </a>

      </div>
      <div class="col-12 col-md-4 order-0 order-md-1 p-3 p-md-4">
        <h4 class="font-base font-weight-bold">Dirección de Impuestos y Aduanas Nacionales</h4>
        <p class="font-tiny"> {{'pie.pie1'| translate }} </p>
        <p class="font-tiny"> {{'pie.pie2'| translate }} </p>
        <p class="font-tiny"> {{'pie.pie3'| translate }} </p>
        <p class="font-tiny"> {{'pie.pie4'| translate }} </p>
        <p class="font-tiny"> {{'pie.pie5'| translate }} </p>
        <p class="font-tiny"> {{'pie.pie6'| translate }} </p>

        <div class="d-flex justify-content-md-start justify-content-center flex-wrap mt-3">
          <!--Icono twitter-->
          <a href="https://twitter.com/DIANColombia" target="_blank">
            <img [src]="fileIconTwitter" alt="icon-twitter" />
          </a>
          <!--Icono youtube-->
          <a href="https://www.youtube.com/channel/UCkaPJ8afIoa_RDKEHaQIxrw" target="_blank">
            <img [src]="fileIconYoutube" alt="icon-youtube" />
          </a>
          <!--Icono linkedin-->
          <a href="https://www.linkedin.com/company/diancolombia/" target="_blank">
            <img [src]="fileIconLinkedin" alt="icon-linkedin" />
          </a>
          <!--Icono facebook-->
          <a href="https://www.facebook.com/DIANCol/" target="_blank">
            <img [src]="fileIconFacebook" alt="icon-facebook" />
          </a>
          <!--Icono instagram-->
          <a href="https://www.instagram.com/diancolombia/?hl=es-la" target="_blank">
            <img [src]="fileIconInstagram" alt="icon-instagran" />

          </a>
        </div>
      </div>
      <div class="col-12 col-md-4 order-1 order-md-2 p-3 p-md-4">
        <div class=""></div>
        <h4 class="font-base font-weight-bold">
          <i class="fa fa-phone" aria-hidden="true"></i> Contacto a nivel nacional
        </h4>
        <p class="font-tiny">
          PBX (57+1) 607 99 99<br />
          PBX (57+1) 382 45 00<br />
          Fax (57+1) 607 94 50<br />
          <a>Servicio en Línea de Contacto</a><br />
          <a href="https://www.dian.gov.co/atencionciudadano/contactenos/Paginas/PqrsDenuncias.aspx" target="_blank"
            style="text-decoration: underline">PQSR y Denuncias</a>
          |
          <a href="https://webrtc.inconcertcc.com/DianCall/" target="_blank" style="text-decoration: underline">Llamada
            en Línea</a>
          |
          <a href="https://www.dian.gov.co/atencionciudadano/contactenos/Paginas/puntosdecontacto.aspx" target="_blank"
            style="text-decoration: underline">Puntos de contacto</a><br />
          <a>Contact Center Teléfono: 057(1) 3556922</a><br />

          <a href="https://www.dian.gov.co/Paginas/Privacidad.aspx" style="text-decoration: underline"
            target="_blank">Política de privacidad de uso</a>
          |
          <a href="https://www.dian.gov.co/normatividad/Normatividad/Circular%20000001%20de%2025-01-2019.pdf"
            style="text-decoration: underline" target="_blank">Política de tramiento de datos personales</a>
          |
          <a href="https://www.dian.gov.co/Documents/POLITICA_GENERAL_DE_SEGURIDAD_Y_PRIVACIDAD_DE_LA_INFORMACION.pdf"
            style="text-decoration: underline" target="_blank">Política de Seguridad de la Información</a>
          |
          <a href="https://www.dian.gov.co/Paginas/Notificaciones-Judiciales-Formulario.aspx"
            style="text-decoration: underline" target="_blank">Notificaciones Judiciales</a>
        </p>
      </div>
    </div>
  </div>
</footer>