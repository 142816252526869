import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {BaseService} from '../../core/services/base.service';


@Injectable({
  providedIn: 'root'
})
export class UsuarioSistemaService extends BaseService {

  controlExcepcion( error: HttpErrorResponse ) {
    return throwError(error);
  }

  /**
   * return
   * usuario.sistema.ts
   */
  public getUsuario(): Observable<any> {
    const url = this.baseUrlApi + '/v1/common/usuario/sistema/getUsuario';
    return this.http.post(url, null, {headers: this.getHttpHeaders()});
  }


}
