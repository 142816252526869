import { Departamentos } from 'src/app/remate/model/departamentos';
import { Municipios } from 'src/app/remate/model/municipios';
import { Dominio } from './../../remate/model/dominio';
export class SearchModel {
  tipoBienes: Dominio;
  tipoInmuebles: Dominio;
  departamentos: Departamentos;
  municipios: Municipios;
  valorDesde: any;
  valorHasta: any;
  fechaInicio: any;
  fechaInicioData: any;
  fechaFin: any;
  fechaFinData: any;
  forWord: string;
  orderBy: string;

  constructor() {
    this.tipoBienes = null;
    this.tipoInmuebles = null;
    this.departamentos = null;
    this.municipios = null;
    this.valorDesde = null;
    this.valorHasta = null;
    this.orderBy = null;
  }

}
