export interface EstadoValidacionDocumento {
  estado: any;
  colorSemaforo: ColorSemaforo;
}

export enum ColorSemaforo {
  AMARILLO = 'semaforo-yellow-style',
  ROJO = 'semaforo-red-style',
  VERDE = 'semaforo-green-style',
  GRIS = 'semaforo-gray-style'
}
