import { Component, HostListener, OnInit, Inject  } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {MenuService} from '../../../protected/services/menu/menu.service';
import {MenuSvr} from '../../../protected/model/menu.svr';
import {RefreshService} from "../../../protected/services/token/refresh.service";
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/language/language.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  isExpanded = false;
  element: HTMLElement;
  public innerWidth: any;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  menu: MenuSvr[];

  constructor(private menuService: MenuService,
              private refreshService: RefreshService,
              private translate: TranslateService,
              private lang: LanguageService,
              @Inject(DOCUMENT) document) { }

  ngOnInit(): void {
    this.getMenus();
    this.innerWidth = window.innerWidth;
    // REFRESH TOKEN
    this.refreshService.refresh().then(r => {});
    //Internacionalizacion
    this.translate.setDefaultLang(this.lang.browserLanguage());
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 120) {
      let element = document.getElementById('sidenav');
      element.classList.add('sticky');
    } else {
     let element = document.getElementById('sidenav');
       element.classList.remove('sticky');
    }
  }

  private getMenus(): void {
   this.menuService.getMenuUsuario().subscribe(
      (result: any) => {
        this.menu = result.map(r=>{
          //En la tabla menu, en el atributo label se definiria la propiedad en donde estaria la traduccion del item del menu
          r.label = this.translate.instant(r.label);
          return r;
        });
        if (this.menu === null) {
          throw new Error('Error res NULL');
        }
        //console.log('menues',this.menu);
      }, error => {
        console.error(error);
      });
  }
}
