import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LayoutComponent} from './layout/layout.component';
import {SharedModule} from './shared/shared.module';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpConfigInterceptor} from './interceptor/httpconfig.interceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PageNotFoundComponent} from './page-not-found/components/page-not-found/page-not-found.component';
import {DirectivesModule} from './directives/directives.module';
import {ProductService} from './../app/protected/funcionario/validardocumentos/productservice';
import { NumberFormatPipe } from './../app/protected/comun/number/number.pipe';
import { CustomTranslateModule } from './translate/translate.module';
import {DatePipe} from "@angular/common";
import {MessageService} from "primeng/api";

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    PageNotFoundComponent,
    NumberFormatPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DirectivesModule,
    CustomTranslateModule
  ],
  providers: [
    ProductService,
    NumberFormatPipe,
    DatePipe,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    /*{
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }*/
  ],
  bootstrap: [AppComponent],
  exports: [NumberFormatPipe]
})
export class AppModule { }
