<!-- Modal de error -->
<p-dialog class="custom-modal modal-error"
  [(visible)]="viewModalError"
  [styleClass]="'dialog-error'"
  [closable]="closable"
  [showHeader]="closable"
  [resizable]="false"
  [modal]="true"
  [blockScroll]="true"
  [position]="'top'"
  [baseZIndex]="10000"
  (onHide)=onHide($event)>

  <div class="custom-modal__inner">

    <div class="custom-modal__icon">
      <img [src]="icono" width="80">
    </div>

    <div class="custom-modal__content">
      <div class="color-fondo-error text-center">
        <h3 class="titulo-error">{{ titulo | translate }}</h3>
        <div class="container-messaje">
          <span class="color-letra-error size-letter">{{ mensaje | translate }}
            <a (click)="closeModal()" class="a-error">{{ 'modalError.linkClose' | translate }}</a>
          </span>
        </div>
      </div>
    </div>

  </div>
</p-dialog>
