import { DataSendService } from './../../../protected/services/data-send.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AfterViewInit, Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbAlert, NgbCalendar, NgbDateParserFormatter, NgbDatepicker, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { SearchModel } from '../../model/searchModel';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { DominioService } from "../../../protected/services/comun/dominio.service";
import { ModalErrorComponent } from "../modal-error/modal-error.component";
import { AuthService } from "../../../core/services/auth/auth.service";
import { FiltroBienesRemate } from 'src/app/protected/remates/inicio/model/filtro-bienes-remate';
import { MenuNoFuncionario } from '../../services/util.service';
import { CustomCalendarComponent } from '../custom-calendar/custom-calendar.component';
import {DatePipe} from "@angular/common";

const I18N_VALUES = {
  es: {
    weekdays: ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  },
  en: {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  }
};

@Injectable()
export class I18n {
  language = 'es';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private i18n: I18n, private lang: LanguageService) {
    super();
    // Setea el lenguaje del calendario obtenienido del browser
    i18n.language = this.lang.browserLanguage();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
function padNumber(value: number | null) {
  if (!isNaN(value) && value !== null) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}
@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('/');

      let dateObj: NgbDateStruct = { day: <any>null, month: <any>null, year: <any>null }
      const dateLabels = Object.keys(dateObj);

      dateParts.forEach((datePart, idx) => {
        dateObj[dateLabels[idx]] = parseInt(datePart, 10) || <any>null;
      });
      return dateObj;
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ?
      `${padNumber(date.day)}-${padNumber(date.month)}-${date.year || ''}` :
      '';
  }
}
@Component({
  selector: 'app-search-bienes',
  templateUrl: './search-bienes.component.html',
  styleUrls: ['./search-bienes.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})
export class SearchBienesComponent implements OnInit, AfterViewInit {

  private _success = new Subject<string>();
  value: Date;

  @ViewChild('dp') dp: NgbDatepicker;
  @ViewChild('staticAlert', { static: false }) staticAlert: NgbAlert;
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert;

  /** Atributos modal de error */
  @ViewChild(ModalErrorComponent) modalError: ModalErrorComponent;

  /** Referencia al componente del componente de calendario Genérico */
  @ViewChild('fechaAudienciaDesde') fechaAudienciaDesde: CustomCalendarComponent;
  @ViewChild('fechaAudienciaHasta') fechaAudienciaHasta: CustomCalendarComponent;

  private router$: Router;
  public form: FormGroup;
  public formWord: FormGroup;
  searchFilter: SearchModel;
  model: NgbDateStruct;
  date: { year: number, month: number };
  currentDate: number = Date.now();
  tiposBienes: any;
  tiposBInmuenbles: any;
  departamentos: any;
  municipios: any;
  formCriteria = true;
  showMessage = false;
  msjError = [];
  fecha: Date = new Date();
  fechaInicial: any;
  fechaFinal: any;
  today = this.calendar.getToday();
  myDate = new Date();
  isFechaIniFinAudi: boolean = false;

  constructor(private translate: TranslateService,
    private calendar: NgbCalendar,
    private lang: LanguageService,
    private service: SharedService,
    // tslint:disable-next-line: no-shadowed-variable
    private FormBuilder: FormBuilder,
    protected router: Router,
    private dominioServicio: DominioService,
    private authService: AuthService,
    private dataSendService: DataSendService,
              private datepipe: DatePipe) {
    this.router$ = router;
    this.searchFilter = new SearchModel();

    this.form = this.FormBuilder.group({
      tipoBienes: [null],
      tipoInmuebles: [null],
      departamentos: [null],
      municipios: [null],
      valorDesde: [null],
      valorHasta: [null],
      fechaInicio: [null],
      fechaFin: [null]
    });

    this.formWord = this.FormBuilder.group({
      forWord: [null],
    });
  }

  onClick(tipoUsuario: string, numeroTipoUsuario: string) {
    localStorage.setItem('tipoUsuario', tipoUsuario);
    localStorage.setItem('numeroTipoUsuario', numeroTipoUsuario);
    this.router$.navigate(['/home/authentication']);
  }

  ngOnInit(): void {
    // Setea el lenguaje del componente obtenienido del browser
    this.translate.setDefaultLang(this.lang.browserLanguage());
    this.authService.loginJWT().subscribe(
      (res: any) => {
        if (res === null) {
          throw new Error('Error res NULL');
        }
        if (res.accessToken === null) {
          throw new Error('Error accessToken NULL');
        }
        localStorage.setItem('token', res.accessToken);
        // load data
        this.loadData();
      }, error => {
        console.error(error);
      });
  }

  ngAfterViewInit() {
    // validamos error al tratar de autenticarse
    this.validarAuth();
    this.validarRole();
  }

  loadData() {
    localStorage.removeItem('searchcriteria');
    this.form.get('tipoInmuebles').disable();
    this.findAllTypeBienes();
    this.findAllTypeInmuebles();
    this.findAllDepartament();
  }

  searchWord() {
    this.formCriteria = false;
  }

  defaultData() {
    if (this.searchFilter.valorDesde === null) {
      this.searchFilter.valorDesde = 0;
    }

    if (this.searchFilter.valorHasta === null) {
      this.searchFilter.valorHasta = 999999999999999;
    }

    if (!this.fechaAudienciaDesde) {
      this.searchFilter.fechaInicio = {
        day: this.fecha.getDate(),
        month: this.fecha.getMonth() + 1,
        year: this.fecha.getFullYear() - 1
      };
    }

    if (!this.searchFilter.fechaFin) {
      this.searchFilter.fechaFin = {
        day: this.fecha.getDate(),
        month: this.fecha.getMonth() + 1,
        year: this.fecha.getFullYear() + 1
      };
    }
  }

  validData(): boolean {
    this.msjError = [];
    this.showMessage = false;
    let valid = true;

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    this.isFechaIniFinAudi = this.fechaAudienciaDesde && this.fechaAudienciaDesde.dayCalendar ? true : false;

    // tslint:disable-next-line: max-line-length
    const fechaInicio = this.fechaAudienciaDesde && this.fechaAudienciaDesde.dayCalendar? this.fechaAudienciaDesde.dayCalendar.getDate() + '-' + this.fechaAudienciaDesde.dayCalendar.getMonth() + 1 + '-' + this.fechaAudienciaDesde.dayCalendar.getFullYear() : day + '-' + month + '-' + (year);
    this.searchFilter.fechaInicioData = fechaInicio;

    // tslint:disable-next-line: max-line-length
    const fechaFin = this.fechaAudienciaHasta && this.fechaAudienciaDesde.dayCalendar? this.fechaAudienciaDesde.dayCalendar.getDate() + '-' + this.fechaAudienciaDesde.dayCalendar.getMonth() + 1 + '-' + this.fechaAudienciaDesde.dayCalendar.getFullYear() : day + '-' + month + '-' + (year + 1);
    this.searchFilter.fechaFinData = fechaFin;

    let valorDesde = "0";//this.searchFilter?.valorDesde.replaceAll('.','');
    let valorHasta = "0";//this.searchFilter?.valorHasta.replaceAll('.','');

    if (parseInt(valorDesde) > parseInt(valorHasta)) {
      this.showMessage = true;
      valid = false;
      this.msjError.push(this.translate.instant('RQ01MSJ001'));
    }

    if (parseInt(valorHasta) < parseInt(valorDesde)) {
      this.showMessage = true;
      valid = false;
      this.msjError.push(this.translate.instant('RQ01MSJ002'));
    }

    this.fechaInicial = moment(fechaInicio, 'DD-MM-YYYY');
    this.fechaFinal = moment(fechaFin).format('DD-MM-YYYY');

    if (this.fechaInicial > this.fechaFinal) {
      this.showMessage = true;
      valid = false;
      // tslint:disable-next-line: max-line-length
      this.msjError.push(this.translate.instant('RQ01MSJ003'));
    }

    if (this.fechaFinal < this.fechaInicial) {
      this.showMessage = true;
      valid = false;
      // tslint:disable-next-line: max-line-length
      this.msjError.push(this.translate.instant('RQ01MSJ004'));
    }

    return valid;
  }

  searchCriteria() {
    this.formCriteria = true;
  }

  selectToday() {
    this.model = this.calendar.getToday();
  }

  setCurrent() {
    this.dp.navigateTo();
  }

  setDate() {
    this.dp.navigateTo({ year: 2013, month: 2 });
  }

  navigateEvent(event) {
    this.date = event.next;
  }

  findAllTypeBienes() {
    const DominioTipoBien = 1;
    /*this.service.getAllDominio(DominioTipoBien).subscribe(data => {
      data.shift();
      data.pop();
      for (let i = 0; i < data.length; i++) {

        const dominio: Dominio =  data[i];

        if (dominio.descripcion === "Urbana") {
          data.splice(i, 1);

        }
        if (dominio.descripcion === "OTROS") {
          data.splice(i, 1);

        }
        if (dominio.descripcion === 'Postulación y audiencia a cualquier remate') {
          data.splice(i, 1);

        }
        if (dominio.descripcion === "ACTA") {
          data.splice(i, 1);

        }

        if (dominio.descripcion === "PENDIENTE") {
          data.splice(i, 1);

        }

      }
      this.tiposBienes = data;
    });*/

    const nombreDominio = 'TIPO_BIEN';
    this.dominioServicio.getAllDominioPorNombre(nombreDominio).subscribe(data => {
      if (!data) {
        return;
      }
      this.tiposBienes = data;
    });
  }

  findAllTypeInmuebles() {
    /*const DominioTipoInmueble = 2;
    this.service.getAllDominio(DominioTipoInmueble).subscribe(data => {
      data.pop();
      for (let i = 0; i <  data.length; i++) {
        if(data[i].descripcion == "Urbana" ){
          data.splice(i, 1);

        }
        if(data[i].descripcion == "OTROS" ){
          data.splice(i, 1);

        }
        if(data[i].descripcion == "ACTA" ){
          data.splice(i, 1);

        }

        if(data[i].descripcion == "NO CUMPLE" ){
          data.splice(i, 1);

        }
        if(data[i].descripcion == "Postulación y audiencia a uno o varios remates específicos" ){
          data.splice(i, 1);

        }

      }
      this.tiposBInmuenbles = data;
    });*/

    const nombreDominio = 'TIPO_INMUEBLE';
    this.dominioServicio.getAllDominioPorNombre(nombreDominio).subscribe(data => {
      if (!data) {
        return;
      }
      this.tiposBInmuenbles = data;
    });
  }

  findAllDepartament() {
    this.service.getAllDepartamentos().subscribe(departamentos => {
      this.departamentos = departamentos;
    });
  }

  findFilterMunicpiosByDepartamet(id: number) {
    this.service.getAllMunicipios(id).subscribe(municipios => {
      this.municipios = municipios;
    });
  }

  changeDepartament(event) {
    this.municipios = [];
    this.searchFilter.municipios = null;
    if (event) {
      const dep = event.idDepartamento;
      this.findFilterMunicpiosByDepartamet(dep);
    }
  }

  changeTipoBien(event) {
    if (event && event.id === 2) {
      this.form.get('tipoInmuebles').enable();
    } else {
      this.form.get('tipoInmuebles').disable();
      this.searchFilter.tipoInmuebles = null;
    }
  }

  searchRemates() {
    if (this.validData()) {
      this.defaultData();

      let fechaInicio = null;
      let fechaFin = null;

      if (this.fechaAudienciaDesde && this.fechaAudienciaDesde?.dayCalendar) {
        fechaInicio = this.datepipe.transform(this.fechaAudienciaDesde?.dayCalendar, 'dd/MM/yyyy');
      }

      if (this.fechaAudienciaHasta && this.fechaAudienciaHasta?.dayCalendar) {
        fechaFin = this.datepipe.transform(this.fechaAudienciaHasta?.dayCalendar, 'dd/MM/yyyy');
      }

      this.searchFilter.fechaFinData = fechaFin;
      var _searchFilter: FiltroBienesRemate = {
        tipoUsuario: undefined,
        tipoBien: this.searchFilter.tipoBienes ? this.searchFilter.tipoBienes.id : undefined,
        tipoInmueble: this.searchFilter.tipoInmuebles ? this.searchFilter.tipoInmuebles.id : undefined,
        departamento: this.searchFilter.departamentos ? this.searchFilter.departamentos.idDepartamento : undefined,
        ciudad: this.searchFilter.municipios ? this.searchFilter.municipios.idMunicipio : undefined,
        valorDesde: this.searchFilter.valorDesde,
        valorHasta: this.searchFilter.valorHasta,
        fechaInicioAudiencia: this.isFechaIniFinAudi ? fechaInicio : undefined,
        fechaFinAudiencia: this.isFechaIniFinAudi ? fechaFin : undefined,
        palabras: this.searchFilter.forWord,
        isSearchByWord: !this.formCriteria,
        paginado: undefined,
        idAutos: undefined,
        numeroRemate: undefined,
        funcionario: undefined,
        idUsuario: undefined,
        fechaDesde: fechaInicio,
        fechaHasta: fechaFin,
        applyDistinctOnRecords: undefined
      };

      this.dataSendService.setData({
        data: _searchFilter,
        componente: MenuNoFuncionario.REMATES_INICIO,
        redirectInicio: '/home'
      });
      this.router.navigate(['/home/rematesConsulta']);
    }
  }

  searchRematesWord() {
    this.defaultData();
    localStorage.setItem('searchcriteria', JSON.stringify(this.searchFilter));
    this.router$.navigate(['/home/search/remates']);
  }

  closeMsj() {
    this.showMessage = false;
  }

  patternString(attr, data) {
    let num = data.target.value.replace(/\./g, '');
    if (!isNaN(num)) {
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
      num = num.split('').reverse().join('').replace(/^[\.]/, '');
      this.searchFilter[attr] = num;
    } else {
      this.searchFilter[attr] = data.target.value.replace(/[^\d\.]*/g, '');
    }
  }

  private markAndValidateAllInputs(anyForm: FormGroup) {
    for (let inner in anyForm.controls) {
      anyForm.get(inner).markAsTouched();
      anyForm.get(inner).updateValueAndValidity();
    }
  }


  /**
   * valida error al tratar de autenticarse
   */
  private validarAuth() {
    const errorAuth = localStorage.getItem('errorAuth');
    if (!errorAuth) {
      return;
    }
    localStorage.removeItem('errorAuth');
    this.showMessageErrorPopup(errorAuth);
  }

  /**
   * Muestra mensaje de error en el componente modal
   */
  private showMessageErrorPopup(mensaje = '') {
    this.modalError.titulo = 'Error';
    this.modalError.mensaje = mensaje;
    this.modalError.data = 'error-interno';
    this.modalError.viewModalError = true;
  }

  /**
   * valida error al tratar de autenticarse
   */
  private validarRole() {
    const errorAuthRole = localStorage.getItem('role');
    if (!errorAuthRole) {
      return;
    }
    localStorage.removeItem('role');
    this.showMessageErrorPopup(this.translate.instant('authentication.errorRole'));
  }

}
