<div class="example-container">
  <mat-sidenav-container class="example-sidenav-container" autosize>
    <mat-sidenav
      #sidenav
      [mode]="innerWidth < 768 ? 'over' : 'side'"
      class="example-sidenav"
      [opened]="innerWidth < 768 ? 'false' : 'true'"
      id="sidenav">
      <mat-nav-list class="dashboard pt-0" *ngIf="menu">
        <a mat-list-item (click)="isExpanded = !isExpanded"
          class="bar-menu d-none d-md-block"
          style="height: 61px;">
          <ng-template [ngTemplateOutlet]="toggleItemAction"></ng-template>
        </a>
        <a mat-list-item (click)="sidenav.toggle()"
          class="bar-menu d-block d-md-none"
          style="height: 61px;">
          <ng-template [ngTemplateOutlet]="toggleItemAction"></ng-template>
        </a>
        <a mat-list-item *ngFor="let item of menu" routerLinkActive="active-link" [routerLink]="item?.routerLink">
          <i mat-list-icon class="{{ item?.icon }}"></i>
          <p matLine *ngIf="isExpanded">{{ item?.label }}</p>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="example-sidenav-content">
      <div class="container-fluid mx-lg-5">
        <div class="row">
          <div class="col-12 d-flex d-md-none">
              <mat-nav-list class="toggleSpace">
                <a mat-list-item (click)="sidenav.toggle(); isExpanded = true">
                  <img class="bar-menu" src="assets/images/leftmenu.svg" alt="icon menu">
                </a>
              </mat-nav-list>
          </div>
          <div class="col-12">
              <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>


<ng-template #toggleItemAction >
  <span *ngIf="isExpanded" class="d-flex w-100 justify-content-between align-items-center">
    <span style="font-size: 17px;">Menu</span>
    <img class="bar-menu isclose" src="assets/images/close.svg" alt="icon menu close">
  </span>
  <span *ngIf="!isExpanded">
    <img class="bar-menu" src="assets/images/leftmenu.svg" alt="icon menu">
  </span>
</ng-template>
