
<div>
    <div #modalView class="pdfWrapper">
      <object
        data="assets/docs/manual.pdf"
        type="application/pdf"
        width="560"
        height="349" >
      </object>
    </div>
</div>
