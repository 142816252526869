
<hr>

<div class="container informacion pb-3 pb-md-5 pt-0 pt-md-4">
  <strong class="mb-2 d-block">{{ "rematesDetalle.infoGeneral.info" | translate }}</strong>
  <div class="align-items-center row">
    <div class="col-md-3 col-md-4 col-sm-6 mt-1">
      <li>
        <a href="https://www.dian.gov.co/Transaccional/Remate-virtual-de-bienes/Paginas/Remate-virtual.aspx"
          target="_blank">{{ "rematesDetalle.infoGeneral.remateVirtual" | translate }}
        </a>
    </li>
    </div>
    <div class="col-md-3 col-md-4 col-sm-6 mt-1">
      <li>
        <a href="https://www.dian.gov.co/Transaccional/Remate-virtual-de-bienes/Paginas/Requisitos-para-participar.aspx"
          target="_blank">{{ "rematesDetalle.infoGeneral.reqOferta" | translate }}
        </a>
      </li>
    </div>
    <div class="col-md-3 col-md-4 col-sm-6 mt-1">
      <li>
        <a href="https://www.dian.gov.co/Transaccional/Remate-virtual-de-bienes/Paginas/Requisitos-para-ser-postor.aspx"
          target="_blank">{{ "rematesDetalle.infoGeneral.reqPostor" | translate }}
        </a>
      </li>
    </div>
    <div class="col-md-3 col-md-4 col-sm-6 mt-1">
      <li>
        <a href="https://www.dian.gov.co/Transaccional/Remate-virtual-de-bienes/Paginas/Requisitos-para-ser-postor.aspx"
            target="_blank">{{ "rematesDetalle.infoGeneral.reqRepresentante" | translate }}
        </a>
      </li>
    </div>
    <div class="col-md-3 col-md-4 col-sm-6 mt-1">
      <li>
        <a href="https://www.dian.gov.co/Transaccional/Remate-virtual-de-bienes/Paginas/Requisitos-para-ser-postor.aspx"
          target="_blank">{{ "rematesDetalle.infoGeneral.reqApoderado" | translate }}
        </a>
      </li>
    </div>
    <div class="col-md-3 col-md-4 col-sm-6 mt-1">
      <li>
        <a href="https://www.dian.gov.co/tramitesservicios/tramites-y-servicios/tributarios/Paginas/tramites-tributarios.aspx"
          target="_blank">{{ "rematesDetalle.infoGeneral.preguntasFrecuentes" | translate }}
        </a>
      </li>
    </div>
  </div>
</div>
