import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { SelectItem } from "primeng";
import { LanguageService } from "src/app/core/services/language/language.service";
import { FiltroBienesRemate } from "src/app/protected/remates/inicio/model/filtro-bienes-remate";
import { Dominio } from "src/app/remate/model/dominio";
import { Ordenamiento, Paginate } from "src/app/remate/model/paginate";
import { SharedService } from "../services/shared.service";
import { UtilService } from "../services/util.service";

/**
 * Clase que contiene métodos comunes entre componentes
 * @since 1.0.0.
 */
export abstract class BaseUtil {

  departamentos: SelectItem[];
  tiposInmuebles: SelectItem[];
  tipoBien: SelectItem[];
  municipios: SelectItem[];
  tipoInmuebleDisabled: boolean;
  nameSpinner: string;
  searchFilter: FiltroBienesRemate;
  paginate: Paginate;
  funcionarios: SelectItem[];

  /**
   * Constructor de la clase `BaseUtil`
   * @param translateService Servicio que se encarga de traducir el contenido de la aplicación
   * @param languageService Servicio que extrae el idioma del navegador
   * @param sharedService Servicio que contiene métodos de consulta comunes
   * @param utilService Servicio de utilería
   * @param spinner Servicio que se encarga de mostrar u ocultar el spinner
   */
  constructor(protected translateService: TranslateService, protected languageService: LanguageService,
              protected sharedService: SharedService, protected utilService: UtilService,
              protected spinner: NgxSpinnerService) {
    this.translateService.setDefaultLang(this.languageService.browserLanguage());
    this.tipoInmuebleDisabled = true;
  }

  /**
   * Método que inicializa los valores necesarios para realizar el filtro de información.
   * Los departamentos, los tipos de bien, los tipos de inmueble y los municipios son gestionados
   * por este método.
   */
  protected initSearchValues() {
    this.findAllTypeBien();
    this.findAllDepartament();
  }

  /** Método que lista los tipos de inmuebles */
  protected findAllTypeBien() {
    this.sharedService.getAllDominioPorNombre('TIPO_BIEN').subscribe(data => {
      this.tipoBien = [];
      (data as Dominio[]).forEach(x => {
        this.tipoBien.push({
          label: String(x.descripcion).toUpperCase(),
          value: x.id
        });
      });
    }, err => console.error(err));
  }

  /** Método que lista todos los departamentos */
  protected findAllDepartament() {
    this.sharedService.getAllDepartamentos().subscribe(departamentos => {
      this.departamentos = [];
      (departamentos as any[]).forEach(x => {
        this.departamentos.push({
          label: x.nombreDepartamento,
          value: x.idDepartamento
        });
      });
    }, err => console.error(err));
  }

  /** Método que lista todos los funcionarios responsables */
  protected findAllFuncionario() {
    this.sharedService.getAllFuncionario().subscribe(funcionarios => {
      this.funcionarios = [];
      (funcionarios as any[]).forEach(x => {
        this.funcionarios.push({
          label: x.nombres + ' ' + x.apellidos,
          value: x.idFuncionario
        });
      });
    }, err => console.error(err));
  }

  /**
   * Método que se encarga de buscar los municipio de acuerdo a su departamento
   * @param evento Evento lanzado cuando se ha seleccionado un valor de la lista
   */
  findFilterMunicpiosByDepartamet(evento: any) {
    if (evento.value) {
      this.spinner.show(this.nameSpinner);
      const idDepartamento = evento.value;
      this.sharedService.getAllMunicipios(idDepartamento).subscribe(municipios => {
        this.municipios = [];
        (municipios as any[]).forEach(x => {
          this.municipios.push({
            label: x.nombreMunicipio,
            value: x.idMunicipio
          });
        });
      }, err => {
        console.error(err);
        this.spinner.hide(this.nameSpinner);
      }, () => this.spinner.hide(this.nameSpinner));
    } else {
      this.municipios = [];
    }
  }

  /**
   * Método que lista los tipos de inmuebles cuando el tipo de bien cambia
   * @param evento Evento que se dispara cuando el tipo de bien es cambiado
   */
  changeTipoBien(evento: any) {
    if (evento.value !== null && Number(evento.value) === 2) {
      this.spinner.show(this.nameSpinner);
      this.sharedService.getAllDominioPorNombre('TIPO_INMUEBLE').subscribe(tb => {
        this.tipoInmuebleDisabled = false;
        this.tiposInmuebles = [];
        (tb as Dominio[]).forEach(x => {
          this.tiposInmuebles.push({
            label: x.descripcion.toUpperCase(),
            value: x.id
          });
        });
      }, err => {
        console.error(err);
        this.spinner.hide(this.nameSpinner);
      }, () => this.spinner.hide(this.nameSpinner));
    } else {
      this.tipoInmuebleDisabled = true;
      this.tiposInmuebles = [];
    }
  }

  /**
   * Retorna el tiempo restante para la audiencia
   * @param fechaAudiencia Fecha de la audiencia
   */
  getTiempoRestanteAudiencia(fechaAudiencia: Date): string {
    return this.utilService.getTiempoRestanteAudiencia(fechaAudiencia);
  }

  /**
   * Método que se encarga de asignar el placeHolder a los controles `Select`
   * @param tag Etiqueta que va a ser traducida
   */
  placeHolderTag(tag: string) {
    return this.translateService.instant(tag);
  }

  /** Tipo de usuario logueado */
  protected getTipoUsuario(): number {
    return this.utilService.getNumeroTipoUsuario();
  }

  /**
   * Método que se encarga de asignar el nombre del spinner
   * @param spinner Nombre del spinner
   */
  protected setNameSpinner(spinner: string) {
    this.nameSpinner = spinner;
  }

  /**
   * Método que compara dos fechas y devuelve `true` si la fecha inicial es menor a la fecha final
   * o `false` si ocurre lo contrario.
   * @param fechaInicial Fecha inicial
   * @param fechaFinal Fecha final
   */
  protected compareDates(fechaInicial: Date, fechaFinal: Date): boolean {
    return this.utilService.compareDates(fechaInicial, fechaFinal);
  }

  /**
   * Método que se encarga de transformar la fecha de `string` a `Date`
   * @param fecha Fecha en formato DD/MM/YYYY
   */
  protected formatToDate(fecha: string): Date {
    const splitFecha = fecha.split('/');
    if (splitFecha.length === 0) {
      return null;
    }
    const dia = Number(splitFecha[0]);
    const mes = Number(splitFecha[1]);
    const anio = Number(splitFecha[2]);
    return new Date(anio, mes, dia);
  }

  /**
   * Método que inicializa el filtro
   * @param tipoUsuarioValue Tipo de usuario
   * @param byWord Búsqueda por palabra
   */
  protected initFilter(tipoUsuarioValue: number, byWord?: boolean) {
    const usuario = localStorage.getItem('idExterno');
    this.searchFilter = {
      tipoUsuario: tipoUsuarioValue,
      isSearchByWord: byWord,
      idUsuario: (usuario !== null) ? Number(usuario) : null
    };
  }

  /**
   * Método que se encarga de retornar el paginado
   * @param numberPage Número de página
   * @param sizePage Tamaño del paginado
   * @param orderData Ordenamiento de la información
   * @param orderColumn Ordenamiento por columna. Depende de `orderData`
   */
  protected getPaginate(numberPage: number, sizePage: number, orderData?: Ordenamiento, orderColumn?: string) {
    return this.paginate = {
      pageNumber: numberPage,
      pageSize: sizePage,
      order: (orderData === undefined) ? null : orderData,
      columnOrderName: (orderColumn === undefined) ? null : orderColumn
    };
  }

}
