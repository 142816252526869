import {Auto} from '../../protected/funcionario/model/auto';

export class DocumentModel {

  id: string;
  idDocumento: number;
  nroRadicado: number;
  nombreArchivo: string;
  descripcion: string;
  totalFolios: number;
  rutaDocumento: string;
  idAuto: Auto;

}
