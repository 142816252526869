import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {BaseService} from '../../../core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class RefreshService extends BaseService {

  // 15 min
  private static REFRESH_MS = 300000; // 900000;

  controlExcepcion(error: HttpErrorResponse) {
    return throwError(error);
  }

  /**
   * posiblemente el cliente no interactue con la aplicacion mientras este en
   * TEAMS, para este tema se crea una funcion que cada cierto tiempo llame
   * al servicio de refresh token
   */
  public async refresh() {
    setInterval(() => {
      this.getRefresh().subscribe(r => {
      }, error => {
        console.error(error);
      });
    }, RefreshService.REFRESH_MS);
  }

  private getRefresh(): Observable<any> {
    const url = this.baseUrlApi + '/v1/common/refresh/token';
    return this.http.get(url, {headers: this.getHttpHeaders()});
  }

}
