<p-dialog class="custom-modal" [ngClass]="modalDarkStyle"
  [(visible)]="viewModal"
  [styleClass]="styleDialog"
  [closable]="false"
  [showHeader]="false"
  [resizable]="false"
  [modal]="modal"
  [blockScroll]="blockScroll"
  [focusOnShow]="false"
  [position]="'top'"
  [baseZIndex]="10000">
  <div class="row" [ngClass]="containerClass">
    <ng-container [ngTemplateOutlet]="container">
    </ng-container>
  </div>
</p-dialog>

<ng-template #container>
  <ng-content></ng-content>
</ng-template>
