import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  hasFullView = false;
  title: any;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.setHasFullView()
  }

  private setHasFullView() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.hasFullView = params.hasFullView || false;
    });
  }

}
