import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

/**
 * Servicio que administra los procesos recurrentes
 * @since 1.0.0.
 */
@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private router: Router) { }

  /** Retorna la variable local `numeroTipoUsuario` */
  getNumeroTipoUsuario(): number {
    return Number(localStorage.getItem('numeroTipoUsuario'));
  }

  getUserLogin(): string {
    return localStorage.getItem('userLogin');
  }

  /**
   * Método que se encarga de redirigir al componente especificado
   * @param linkComponent Enlace que redirige al componente especificado
   */
  returnHome(linkComponent: string): void {
    this.router.navigate([linkComponent]);
  }

  /**
   * Método que agrega los separadores de miles al valor especificado
   * @param value Valor que se requiere formatear
   */
  formatValueThousand(value: any) {
    return Number(value).toLocaleString();
  }

  /**
   * Método que retorna el tiempo restante para la audiencia
   * @param fecha Fecha de la audiencia
   */
  getTiempoRestanteAudiencia(fecha: Date): string {
    const fechaAudienciaFormat = moment(fecha);
    const fechaActualFormat = moment();

    const days = fechaAudienciaFormat.diff(fechaActualFormat, 'days');
    fechaActualFormat.add(days, 'days');
    const hours = fechaAudienciaFormat.diff(fechaActualFormat, 'hours');
    fechaActualFormat.add(hours, 'hours');
    const minutes = fechaAudienciaFormat.diff(fechaActualFormat, 'minutes');
    fechaActualFormat.add(minutes, 'minutes');

    let dias = (days <= 0) ? '00' : days.toString();
    let horas = (hours <= 0) ? '00' : hours.toString();
    let minutos = (minutes <= 0) ? '00' : minutes.toString();
    dias = (Number(dias) > 0 && Number(dias) < 10) ? '0'.concat(dias) : dias;
    horas = (Number(horas) > 0 && Number(horas) < 10 ) ? '0'.concat(horas) : horas;
    minutos = (Number(minutos) > 0 && Number(minutos) < 10) ? '0'.concat(minutos) : minutos;
    return dias.concat('D:').concat(horas).concat('H:').concat(minutos).concat('M');
  }

  /**
   * Método que compara dos fechas y devuelve `true` si la fecha inicial es menor o igual a la fecha final
   * o `false` si ocurre lo contrario.
   * @param fechaInicial Fecha inicial
   * @param fechaFinal Fecha final
   */
  compareDates(fechaInicial: Date, fechaFinal: Date): boolean {
    const initial = moment(fechaInicial);
    const final = moment(fechaFinal);
    const isInitialHigher = initial.isSameOrBefore(final);
    return isInitialHigher;
  }

    /**
   * Método que compara dos fechas y devuelve `true` si la fecha inicial es menor que la fecha final
   * o `false` si ocurre lo contrario.
   * @param fechaInicial Fecha inicial
   * @param fechaFinal Fecha final
   */
     compareLessThanDates(fechaInicial: Date, fechaFinal: Date): boolean {
      const initial = moment(fechaInicial);
      const final = moment(fechaFinal);
      const isInitialHigher = initial.isBefore(final);
      return isInitialHigher;
    }

  /**
   * Método que retorna las opciones del menú que deben ser eliminadas.
   * Estas opciones se encuentran almacenadas en el `LocalStorage`.
   */
  getRemoveOptions(): string[] {
    if (localStorage.getItem('removeOptions') !== null) {
      return JSON.parse(localStorage.getItem('removeOptions')) as string[];
    }
    return [];
  }

}

/** Enumeración que administra los tipos de usuarios logueados */
export enum TipoUsuario {
  NO_LOGUEADO = 0,
  POSTOR = 2,
  APODERADO = 3,
  DEUDOR = 4,
  INVITADO = 5
}

/**
 * Enumeración que administra las los estados del menú activo y gestiona la
 * recepción de datos entre componentes.
 */
export enum MenuNoFuncionario {
  POSTULACIONES = 1,
  CONSULTA_REMATE = 2,
  REMATES_ASIGNADOS = 3,
  SOLICITUD_INFORMACION = 4,
  DOCUMENTO_ANEXO = 5,
  AUTORIZACIONES = 6,
  REMATES_INICIO = 7,
  BIENES_INICIO = 8,
  POSTULACIONES_INICIO = 9
}

/** Interfaz que administra las opciones del menú -> No funcionario */
export interface MenuList {
  name: string;
  isActive: boolean;
  link: string;
  stateLinkComponent: MenuNoFuncionario;
}

/**
 * Enumeración que administra las los estados del menú activo y gestiona la
 * recepción de datos entre componentes.
 */
export enum MenuDirector {
  INICIO = 1001,
  AUTOS_REASIGNAR = 1002,
  REASIGNAR_AUTO = 1003,
  CORREGIR_AUDIENCIA = 1004,
  AUTOS_CORREGIDOS = 1005
}
