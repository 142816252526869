import { Component, OnInit } from '@angular/core';
import { MenuService } from 'src/app/protected/services/menu/menu.service';

@Component({
  selector: 'app-institutional-logos',
  templateUrl: './institutional-logos.component.html',
  styleUrls: ['./institutional-logos.component.scss'] 
})
export class InstitutionalLogosComponent implements OnInit {

  //variables que almacenan la imagenes
  fileImagenDianPrincipal: string = null;
  fileImagenRemates: string = null;
  fileImagenPotenciaVida: string = null;

  constructor(private menuService: MenuService) { }

  ngOnInit(): void {
    this.initCargaLogosImagenes();
  }

  /**
* get imagenes y logos actualizados
* 
*/
  private initCargaLogosImagenes() {
    this.menuService.getImagenesYLogosPorParametros("IMAGEN_REMATES_ACTUALIZADOS", "Dian-principal").subscribe((data) => {
      this.fileImagenDianPrincipal = data;
      console.log("la data que ingresa es: " + this.fileImagenDianPrincipal);
    });

    this.menuService.getImagenesYLogosPorParametros("IMAGEN_REMATES_ACTUALIZADOS", "Remates").subscribe((data) => {
      this.fileImagenRemates = data;
    });

    this.menuService.getImagenesYLogosPorParametros("IMAGEN_REMATES_ACTUALIZADOS", "Logo-Potencia-dorado").subscribe((data) => {
      this.fileImagenPotenciaVida = data;
    });

  }

}
