import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router, UrlTree} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Session} from 'protractor';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {LanguageService} from 'src/app/core/services/language/language.service';
import {DOCUMENT} from '@angular/common';
import {UsuarioSistema} from '../../../model/usuario.sistema';
import {UsuarioSistemaService} from '../../../service/common/usuario.sistema.service';
import {ErrorRemates} from "../../../protected/comun/model/error/error.remates";
import {UsuarioExtService} from 'src/app/protected/services/comun/usuario.ext.service';
import {TipoUsuarioEnum} from "../../../protected/comun/enums/tipo.usuario.enum";
import {RoleEnum} from "../../../core/enum/role.enum";

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent implements OnInit {
  private router$: Router;
  private fb$: FormBuilder;
  private auth$: AuthService;
  form: FormGroup;
  hidden: boolean;
  sesion: Session;
  errors: any;
  urlCookie: UrlTree;
  cookie: string;

  constructor(protected router: Router,
              protected fb: FormBuilder,
              protected auth: AuthService,
              private translate: TranslateService,
              private lang: LanguageService,
              @Inject(DOCUMENT) private document: Document,
              private usuarioSistemaService: UsuarioSistemaService,
              private usuarioExtService : UsuarioExtService) {
    this.router$ = router;
    this.fb$ = fb;
    this.auth$ = auth;
    this.hidden = true;
  }

  get formLogin() {
    return this.form.controls;
  }

  ngOnInit() {
    try {
      // Setea el lenguaje del componente obtenienido del browser
      this.translate.setDefaultLang(this.lang.browserLanguage());
      // this.initialForm();
      this.initLogin();
    } catch (error) {
      console.error('Error message', error);
    }
  }

  initialForm() {
    this.form = this.fb$.group({
      username: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
      password: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
      messageVal: '',
      hidden: true,
      sucess: false
    });
  }

  onSubmit(values: { username: string; password: string; }) {
    if (this.formLogin.username.status === 'INVALID' ||
      this.formLogin.password.status === 'INVALID') {
      return;
    }

    /*this.auth$.login(values.username, values.password)
              .pipe(first())
              .subscribe(response => {

                // if (response) {
                // }
              },
              error => {
                this.hidden = false;
              },
              () => {
                // Sin errores enruta a la sección
                this.router$.navigate(['/protected']);
              });*/

    this.router$.navigate(['/protected']);
    return;

    /*
     * Spring Security impl
     */
    let res = {
      accessToken: ''
    };
    this.auth.login(values.username, values.password).subscribe(
      (result: any) => {
        res = result;
        if (res === null) {
          throw new Error('Error res NULL');
        }
        if (res.accessToken === null) {
          throw new Error('Error accessToken NULL');
        }
        localStorage.setItem('token', res.accessToken);
        this.loadUsuarioSistema();
        this.router$.navigate(['/protected']);
      }, error => {
        console.error(error);
      });
  }

  private initLogin(): void {

    // validamos si ya tenemos el token
    this.urlCookie = this.router.parseUrl(this.router.url);

    this.cookie = this.urlCookie.queryParams['c'];
    if (this.cookie != null) {
      //console.log('cookie', this.cookie);
      this.getLoginToken();
      return;
    }

    // login page
    this.getLoginPage();

  }

  /**
   * login page
   */
  private getLoginPage(): void {
    let res = {
      urlLogin: ''
    };

    this.auth.getUrlLogin().subscribe(
      (result: any) => {
        res = result;
        if (res === null) {
          throw new Error('Error res NULL');
        }
        if (res.urlLogin === null) {
          throw new Error('Error urlLogin NULL');
        }
        this.document.location.href = res.urlLogin;
        // tslint:disable-next-line:max-line-length
        // this.document.location.href = 'http://localhost:4200/home/authentication/?c=N_2_3133303030303230323030343134_5f5a6e85_N_5072756562617332303230';
      }, error => {
        console.error(error);
      });
  }

  /**
   * login page
   */
  private getLoginToken(): void {

    // control de cambio # 13
    const numeroTipoUsuario =
      localStorage.getItem('numeroTipoUsuario');

    // tslint:disable-next-line:radix
    const intCase = parseInt(numeroTipoUsuario);
    localStorage.setItem('usuarioMenu', numeroTipoUsuario);

    const request = {
      authorization: '',
      tipoUsuario: '',
      numeroUsuario: intCase
    };

    const tipoUsuario = localStorage.getItem('tipoUsuario');
    if (tipoUsuario === null) {
      throw new Error('Error tipoUsuario NULL');
    }

    request.authorization = this.cookie;
    request.tipoUsuario = tipoUsuario;

    let response = {
      idUsuario: 0,
      jwt: {
        accessToken: ''
      }
    };

    this.auth.loginUser(request).subscribe(
      (result: any) => {
        response = result;
        if (response === null) {
          throw new Error('Error res NULL');
        }
        if (response.jwt.accessToken === null) {
          throw new Error('Error accessToken NULL');
        }
        localStorage.setItem('token', response.jwt.accessToken);
        localStorage.setItem('idExterno', response.idUsuario.toString());
        // this.router$.navigate(['/protected/testHtmlToPdfComponent']);
        this.obtenerDatosUsuario();
        //this.redirectUser();

      }, error => {
        const e: ErrorRemates = error;
        console.error(error);
        if ('ERROR ROLE CREDENTIALS' === e.error) {
          localStorage.setItem('role', e.error);
          this.router$.navigate(['/home']);
          return;
        }
        localStorage.setItem("errorAuth", e.error);
        this.router$.navigate(['/home']);
      });
  }

  private obtenerDatosUsuario() {
    this.usuarioExtService.getUsuarioExt().subscribe(resp => {
      //se guarda idSeccional para realizar consulta numeroDepositoJudicial
      localStorage.setItem('idSeccional', resp.idSeccional);
      const nombre = resp.nombres + " " + resp.apellidos;
      localStorage.setItem('funcionarioLogin', nombre)
      const usuario = resp;
      //console.log('usuario: ',usuario);
      if (usuario?.role == 24) {
        localStorage.removeItem('numeroTipoUsuario');
        localStorage.setItem('numeroTipoUsuario', usuario?.role);
        // validamos si el rol pertenece a las credenciales ingresadas
      } else if (!this.validarRolCredenciales(usuario?.role)) {
        return;
      }
      this.redirectUser();
    });
  }



  private redirectUser(): void {
    const numeroTipoUsuario =
      localStorage.getItem('numeroTipoUsuario');

    const intCase = parseInt(numeroTipoUsuario);

    switch (intCase) {
      case 0:
        this.router$.navigate(['/protected/director']);//Mantis: 0000485
        break;
      case 1:
        this.router$.navigate(['/protected/funcionario/inicio']);
        break;
      case 2:
          this.router$.navigate(['/protected/rematesInicio']);
          break;
      case 3:
        this.router$.navigate(['/protected/rematesInicio']);
        break;
      case 4:
        this.router$.navigate(['/protected/bienesInicio']);
        break;
      case 5: 
         this.router$.navigate(['/protected/director-central/Inicio']);
         break;
      case 24:
        this.router$.navigate(['/protected/funcionario/validarDocumentos']); // Mantis 0000701 secretario
        break;
      default:
        break;
    }
  }

  /**
   * cargamos usuario sistema
   */
  public loadUsuarioSistema(): void {
    this.usuarioSistemaService.getUsuario().subscribe(
      data =>{

        if(!data){
          throw new Error('Usuario sistema no encontrado')
        }

        let usuarioSistema: UsuarioSistema = data;

        if(usuarioSistema.error != null){
          throw new Error(usuarioSistema.error)
        }

        if(usuarioSistema.token == null){
          throw new Error('Usuario sistema no encontrado')
        }

        // cargamos el usuario sistema
        localStorage.setItem('usuarioSistema', usuarioSistema.token);

      }, error => {
        console.error(error);
      });

  }

  /**
   * VALIDAMOS EL ROLE QUE INTENTA INGRESAR A REMATES
   * @param role rol
   */
  private validarRolCredenciales(role: number) {
    const numeroTipoUsuario =
      localStorage.getItem('numeroTipoUsuario');
    const tipoUsuario = Number(numeroTipoUsuario);
    // si no es funcionario dian no valida el ROL
    if (!this.validarEsFuncionarioDIAN(tipoUsuario)) {
      return true;
    }
    localStorage.setItem('usuarioMenu', numeroTipoUsuario);
    let roleValido = false;
    switch (tipoUsuario) {
      case TipoUsuarioEnum.FUNCIONARIO:
        if (RoleEnum.FUNCIONARIO === role || RoleEnum.SECRETARIO === role) {
          roleValido = true;
        }
        break;
      case TipoUsuarioEnum.DIRECTOR:
        if (RoleEnum.DIRECTOR === role) {
          roleValido = true;
        }
        break;
    }
    if (!roleValido) {
      this.router$.navigate(['/home']);
      localStorage.setItem('role', 'ERROR ROLE CREDENTIALS');
      return roleValido;
    }
    return roleValido;
  }

  /**
   * es funcionario dian
   */
  private validarEsFuncionarioDIAN(tipoUsuario: number) {
    return TipoUsuarioEnum.FUNCIONARIO === tipoUsuario || TipoUsuarioEnum.DIRECTOR === tipoUsuario;
  }

}
