<div class="col-12 border-bottom-blue ">
  <div class="container">
    <div class="justify-content-between row">
      <div
        class="align-items-center col-12 col-lg-4 col-md-5 col-sm-6 d-flex justify-content-center justify-content-sm-start py-3">
        <img [src]="fileImagenPotenciaVida" class="logo-co-dorado" />
      </div>
      <div class="col-12 col-lg-6 col-md-7 col-sm-6 d-flex justify-content-center justify-content-sm-end py-3 ">
        <div class="container-logos">
          <div class="logo-remates-container">
            <img [src]="fileImagenRemates" class="img-fluid" />
          </div>
          <div class="div-separator">
          </div>
          <div class="logo-dian-container">
            <img [src]="fileImagenDianPrincipal" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>