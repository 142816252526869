import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Input() bdColor: string;
  @Input() size: string;
  @Input() color: string;
  @Input() type: string;
  @Input() fullScreen: boolean;
  @Input() isloadedSpinner: boolean;
  @Input() name: string;

  constructor() { }

  ngOnInit(): void {
    this.bdColor = (this.bdColor === undefined) ? 'rgba(24,24,24,0.56)' : this.bdColor;
    this.size = (this.size === undefined) ? 'default' : this.size;
    this.color = (this.color === undefined) ? '#fff' : this.color;
    this.type = (this.type === undefined) ? 'ball-clip-rotate' : this.type;
    this.fullScreen = (this.fullScreen === undefined) ? false : this.fullScreen;
    this.name = (this.name === undefined) ? 'primary' : this.name;
  }

}
