import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgModel } from '@angular/forms';
import * as moment from 'moment';
import { Calendar } from 'primeng';
import { LanguageCalendar } from './model/language-calendar';

@Component({
  selector: 'app-custom-calendar',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.scss']
})
export class CustomCalendarComponent implements OnInit {

  dayCalendar: Date;
  @Input() dateFormat: string;
  @Input() minDateValue: Date;
  @Input() maxDateValue: Date;
  @Input() showButtonBar: boolean;
  @Input() monthNavigator: boolean;
  @Input() yearNavigator: boolean;
  @Input() yearRange: string;
  @Input() disabled: boolean;
  /** No muestra un valor inicial en el input del calendario */
  @Input() isEmpty: boolean;
  /** Place holder del inpu */
  @Input() placeHolder: string;
  /** Asigna el estilo de error al componente */
  @Input() errorStyleCalendar: boolean;
  /** Fecha seleccionada del calendario */
  @Output() dateSelectedCal: EventEmitter<any> = new EventEmitter<any>();
  /** Determinar si el campo es requerido */
  @Input() required: boolean;
  /** Mensaje a mostrar de campo obligatorio */
  @Input() messageRequired: string;
  /** label que acompaña al campo */
  @Input() label: string;
  /** clase label que acompaña al campo */
  @Input() labelClass: string;
  /** time only */
  @Input() timeOnly: boolean;

  locale: any;

  /** Referencia a la clase `Calendar` de PrimeNg */
  @ViewChild('calendarNg') calendarNg: Calendar;

  @ViewChild('customCalendar', { static: false }) customCalendar: NgModel;

  constructor() { }

  ngOnInit(): void {
    this.isEmpty = (this.isEmpty === undefined) ? false : this.isEmpty;
    this.dayCalendar = (this.isEmpty) ? null : new Date();
    this.errorStyleCalendar = (this.errorStyleCalendar === undefined) ? false : this.errorStyleCalendar;
    this.dateFormat = (this.dateFormat === undefined) ? 'dd-mm-yy' : this.dateFormat;
    this.minDateValue = (this.minDateValue === undefined || this.minDateValue === null) ? null : this.minDateValue;
    this.maxDateValue = (this.maxDateValue === undefined || this.maxDateValue === null) ? null : this.maxDateValue;
    this.showButtonBar = (this.showButtonBar === undefined) ? true : this.showButtonBar;
    this.monthNavigator = (this.monthNavigator === undefined) ? true : this.monthNavigator;
    this.yearNavigator = (this.yearNavigator === undefined) ? true : this.yearNavigator;
    this.disabled = (this.disabled === undefined) ? false : this.disabled;
    this.yearRange = (this.yearRange === undefined) ? '2020:2030' : this.yearRange;
    this.placeHolder = (this.placeHolder === undefined) ? 'DD-MM-AAAA' : this.placeHolder;
    this.locale = new LanguageCalendar().getLanguageEspanish();
    this.required = (this.required === undefined || this.required === null) ? false : this.required;
    this.messageRequired = (this.messageRequired === undefined || this.messageRequired === null) ? 'calendario.obligatorio' : this.messageRequired;
    this.label = (this.label === undefined || this.label === null) ? undefined : this.label;
    this.labelClass = (this.labelClass === undefined || this.labelClass === null) ? '' : this.labelClass;
    this.timeOnly = (this.timeOnly === undefined || this.timeOnly === null) ? false : this.timeOnly;
    if (this.timeOnly === true) {
      this.placeHolder = 'HH:MM';
    }
  }

  /**
   * Método que obtiene la fecha en formato de String
   * @param format Formato de fecha (Opcional)
   */
  getDateString(format?: string): string {
    format = (format === undefined) ? 'DD/MM/YYYY' : format;
    return moment(this.dayCalendar).format(format);
  }

  /**
   * Obtiene la fecha seleccionada del calendario
   * @param date fecha seleccionada
   */
  selectDate(date: any) {
    this.dateSelectedCal.emit(date);
  }

  iniciarCalendario() {
    this.ngOnInit();
  }

  /**
   * Establece propiedades si el input tiene error
   */
  setErrors() {
    if (this.customCalendar && !this.customCalendar.control.valid) {
      this.customCalendar.control.markAsTouched();
      this.customCalendar.control.markAsDirty();
    }
  }

  /**
   * Limpia errores
   */
  clearErrors() {
    if (this.customCalendar && this.customCalendar.control) {
      this.customCalendar.control.setErrors(null);
      this.customCalendar.control.reset();
    }
  }

  /**
   * Valida si no hay fecha seleccionada
   * @returns 
   */
  dateIsEmpty() {
    return this.dayCalendar === null || this.calendarNg === undefined;
  }

  /**
   * Establece valor para el atributo dayCalendar, reciibendo la fecha como string
   * formatos recibidos:
   * 1. DD-MM-YYYY
   * 2. DD/MM/YYYY
   * @param date 
   */
  public setDayCalendarWithString(value: any, ) {
    if (value) {
      this.dayCalendar = this.getFormatedDate(value);
      this.customCalendar.control.setValue(this.getFormatedDate(value));
    }
  }

  /**
   * To format date
   * formatos recibidos:
   * 1. DD-MM-YYYY
   * 2. DD/MM/YYYY
   * @param fechaString
   */
   private getFormatedDate(fechaString: any): Date {
    if (fechaString != null) {
      const now = new Date();
      let arrayFecha: string[] = [];
      if (fechaString.split("-").length > 1) {
        arrayFecha = fechaString.split("-");
      } else if (fechaString.split("/").length > 1) {
        arrayFecha = fechaString.split("/");
      } else {
        arrayFecha[0] = '01';
        arrayFecha[1] = '01';
        arrayFecha[2] = '1990';
      }
      const dayStr = arrayFecha[0].startsWith('0') ? arrayFecha[0].replace('0', '') : arrayFecha[0];
      const monthStr = arrayFecha[1].startsWith('0') ? arrayFecha[1].replace('0', '') : arrayFecha[1];
      const year = Number(arrayFecha[2]);
      const month = Number(monthStr);
      const day = Number(dayStr);
      now.setFullYear(year, month - 1, day);
      return now;
    } else {
      return null;
    }
  }

}
