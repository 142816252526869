<div class="banner">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row mt-2 mb-3">
          <div class="col-12 text-right">
            <div class="dropdownt">
              <button class="btn btn-success dropdown-toggle" type="button" id="dropdownMenuButton"
                data-toggle="collapse" aria-haspopup="true" style="z-index: 10000" data-target="#collapseIngresar"
                aria-expanded="false" aria-controls="collapseIngresar">
                {{ "menuIngresar.ingresar" | translate }}
              </button>
              <div id="collapseIngresar" class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <Strong>{{ "menuIngresar.usuarios" | translate}} </Strong>
                <a class="dropdown-item mt-1" (click)="onClick('noFuncionario', '2')">{{ "menuIngresar.postor" |
                  translate }}</a>
                <a class="dropdown-item" (click)="onClick('noFuncionario', '3')">{{ "menuIngresar.apoderado" | translate
                  }}</a>
                <a class="dropdown-item" (click)="onClick('noFuncionario', '4')">{{ "menuIngresar.deudor" | translate
                  }}</a>
                <a class="dropdown-item" (click)="onClick('funcionario', '1')">{{ "menuIngresar.funcionario" | translate
                  }}</a>
                <a class="dropdown-item" (click)="onClick('funcionario', '0')">{{ "menuIngresar.director" | translate
                  }}</a>
                  <a class="dropdown-item" (click)="onClick('funcionario', '5')">{{ "menuIngresar.DirectorNivelCentral" | translate
                  }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row my-4">
          <div class="col-12 text-center">
            <strong>
              <h1 class="title">
                {{ "searchBienes.searchRemateTitle" | translate }}
              </h1>
            </strong>
          </div>
        </div>
        <div *ngIf="!formCriteria" [formGroup]="formWord">
          <div class="row card p-4">
            <div class="col-12 col-md-8 offset-md-2 p-0 text-center input-group-sm">
              <input type="text" class="form-control" [(ngModel)]="searchFilter.forWord" formControlName="forWord"
                placeholder="{{ 'searchBienes.valueForWord' | translate }}" aria-label="For word"
                aria-describedby="addon-wrapping" />
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-12 col-md-12 text-center">
              <button type="button" class="btn btn-searchH" (click)="searchCriteria()">
                {{ "searchBienes.searchForCriterio" | translate }}
              </button>
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-12 col-md-12 text-center">
              <button type="button" class="btn btn-success" (click)="searchRematesWord()">
                {{ "searchBienes.searchButton" | translate }}
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="formCriteria">
          <section class="row">
            <div class="col-12 col-md-8 offset-md-2 card flex-row">
              <form class="form-row mt-3" [formGroup]="form">
                <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                  <label>{{ "searchBienes.typeOfGood" | translate }}:</label>
                  <ng-select formControlName="tipoBienes" placeholder="{{ 'searchBienes.typeOfGood' | translate }}"
                    [items]="tiposBienes" bindLabel="descripcion" [(ngModel)]="searchFilter.tipoBienes"
                    class="browser-default custom-select" (change)="changeTipoBien($event)">
                  </ng-select>
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                  <label>{{ "searchBienes.propertyType" | translate }}:</label>
                  <ng-select formControlName="tipoInmuebles" placeholder="{{ 'searchBienes.propertyType' | translate }}"
                    [items]="tiposBInmuenbles" bindLabel="descripcion" [(ngModel)]="searchFilter.tipoInmuebles"
                    class="browser-default custom-select" [readonly]="form.get('tipoInmuebles').disabled">
                  </ng-select>
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                  <label>{{ "searchBienes.state" | translate }}:</label>
                  <ng-select formControlName="departamentos" placeholder="{{ 'searchBienes.state' | translate }}"
                    [items]="departamentos" bindLabel="nombreDepartamento" [(ngModel)]="searchFilter.departamentos"
                    class="browser-default custom-select" (change)="changeDepartament($event)">
                  </ng-select>
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                  <label>{{ "searchBienes.city" | translate }}:</label>
                  <ng-select formControlName="municipios" placeholder="{{ 'searchBienes.city' | translate }}"
                    [items]="municipios" bindLabel="nombreMunicipio" [(ngModel)]="searchFilter.municipios"
                    class="browser-default custom-select" [readonly]="!municipios">
                  </ng-select>
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                  <label>{{ "searchBienes.valueFrom" | translate }}:</label>
                  <input type="text" class="form-control" [(ngModel)]="searchFilter.valorDesde"
                    formControlName="valorDesde" (keyup)="patternString('valorDesde', $event)" maxlength="20"
                    placeholder="$0" aria-label="Valor desde" aria-describedby="addon-wrapping" />
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-3">
                  <label>{{ "searchBienes.valueUpTo" | translate }}:</label>
                  <input type="text" class="form-control" [(ngModel)]="searchFilter.valorHasta"
                    formControlName="valorHasta" (keyup)="patternString('valorHasta', $event)" maxlength="20"
                    placeholder="$999'999.999.999.999" aria-label="Valor hasta" aria-describedby="addon-wrapping" />
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                  <label>{{ "searchBienes.initialDate" | translate }}:</label>
                  <app-custom-calendar #fechaAudienciaDesde [showButtonBar]="false" [minDateValue]="fecha"
                    placeHolder="{{ 'searchBienes.initialDate' | translate }}" [isEmpty]="true">
                  </app-custom-calendar>
                </div>
                <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                  <label>{{ "searchBienes.finalDate" | translate }}:</label>
                  <app-custom-calendar #fechaAudienciaHasta [showButtonBar]="false" [minDateValue]="fecha"
                    placeHolder="{{ 'searchBienes.finalDate' | translate }}" [isEmpty]="true">
                  </app-custom-calendar>
                </div>
              </form>
            </div>
          </section>
          <div class="row mt-4 mb-4">
            <div class="col-12 col-md-12 text-center">
              <button type="button" class="btn btn-searchH" (click)="searchWord()">
                {{ "searchBienes.searchForWord" | translate }}
              </button>
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-12 col-md-12 text-center">
              <button type="button" class="btn btn-success px-5" (click)="searchRemates()">
                {{ "searchBienes.searchButton" | translate }}
              </button>
            </div>
          </div>
        </div>
        <p>
          <ngb-alert #selfClosingAlert *ngIf="showMessage" (click)="closeMsj()">
            <ul>
              <li *ngFor="let msj of msjError">{{ msj }}</li>
            </ul>
          </ngb-alert>
        </p>
      </div>
    </div>
  </div>
</div>


<!-- Modal de error -->
<app-modal-error>
</app-modal-error>
