export const environment = {
  production: true,
  backend: 'https://rematesvirtuales.dian.gov.co',
  loginService: 'http://reqres.in',
  url_api: 'https://rematesvirtuales.dian.gov.co',
  urlApi: 'https://rematesvirtuales.dian.gov.co/remate-virtual/api',
  urlauth: 'https://rematesvirtuales.dian.gov.co/remate-virtual/api/auth',
  urlImage: './assets/images',
  socket: 'wss://rematesvirtuales.dian.gov.co/remate-virtual/ws',
  redirect: 'https://rematesvirtuales.dian.gov.co/sistema-virtualizacion-remates/'
};
