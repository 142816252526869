import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {BaseService} from '../../../core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class DominioService extends BaseService {

  controlExcepcion( error: HttpErrorResponse ) {
    return throwError(error);
  }

  /**
   * retorna los dominios por el nombre
   * nombreDominio
   */
  getAllDominioPorNombre(nombreDominio): Observable<any> {
    return this.http.post(this.baseUrlApi + '/v1/common/findDominiosPorNombre', nombreDominio, {headers: this.getHttpHeaders()});
  }

}
