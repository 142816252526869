import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {BaseService} from '../../../core/services/base.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseService {

  getToken() {
    return localStorage.getItem('token');
  }

  controlExcepcion( error: HttpErrorResponse ) {
    return throwError(error);
  }

  public getMenuUsuario(): Observable<any> {
    // control de cambio # 13
    const usuarioMenu =
      localStorage.getItem('usuarioMenu');

    if (!usuarioMenu) {
      console.error('ERROR -> No se encontro usuarioMenu ' + usuarioMenu);
      return null;
    }

    // localStorage.removeItem('usuarioMenu');

    const intCase = Number(usuarioMenu);

    const user = {
      accessToken: this.getToken(),
      numeroUsuario: intCase
    };
    const url = this.baseUrlApi + '/v1/common/findMenuByUser';
    return this.http.post(url, user, {headers: this.getHttpHeaders()});
  }

  public getManualPdf(nombreTablaPametros : string, nombraCampoParametros : string): Observable<any> {
    const url = this.baseUrlApi + '/v1/common/buscarPorNombre';
    return this.http.post<any>(url, JSON.stringify({"nombreTablaPametros":nombreTablaPametros,"nombraCampoParametros":nombraCampoParametros}),{headers: this.getHttpHeaders()});
  }

  public getImagenesYLogosPorParametros(nombreTablaPametros: string, nombreCampoParametros: string): Observable<any> {
    const url = this.baseUrlApi + '/v1/common/getImagenesActualizadas';
    return this.http.post(url, JSON.stringify({ "nombreTablaPametros": nombreTablaPametros, "nombraCampoParametros": nombreCampoParametros }), { headers: this.getHttpHeaders(), responseType: 'text' });
  }
}
