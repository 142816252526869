import { Component, OnInit, Input} from '@angular/core';
/**
 * Componente que muestra la modal con el contenido personalizado
 * que ha sido enviado desde el padre. Para su uso se sugiere
 * utilizar `@ViedChild` para instanciar este componente.
 * @since 1.0.0.
 */
@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {

  /** Muestra u oculta la modal */
  viewModal: boolean;
  /** Tamaño del dialog: Ventana modal */
  styleDialog: string;
  /** Se presenta como modal */
  @Input() modal: boolean;
  /** Bloquea el scroll de la página */
  @Input() blockScroll: boolean;
  /** Estilo del contenedor de la modal */
  @Input() containerClass: string;
  /** Estilo oscuro del modal */
  @Input() modalDark: boolean;
  /** Nombre del estilo oscuro de la modal */
  modalDarkStyle: string;

  constructor() {}

  ngOnInit(): void {
    this.viewModal = false;
    this.styleDialog = "custom-dialog-35";
    this.modal = (this.modal === undefined) ? true : this.modal;
    this.blockScroll = (this.blockScroll === undefined) ? true : this.blockScroll;
    this.containerClass = (this.containerClass === undefined) ? 'p-4' : this.containerClass;
    this.modalDark = (this.modalDark === undefined) ? false : this.modalDark;
    if (this.modalDark) {
      this.modalDarkStyle = 'custom-modal-content';
    }
  }

}
