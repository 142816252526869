import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Actions } from 'src/app/remate/model/actions';
import { Columns } from 'src/app/remate/model/columns';
import { Paginate } from 'src/app/remate/model/paginate';
import { ColorSemaforo, EstadoValidacionDocumento } from '../dynamic-table/model/estado-validacion-documento';

@Component({
  selector: 'app-tabla-dinamica-autorizaciones',
  templateUrl: './tabla-dinamica-autorizaciones.component.html',
  styleUrls: ['./tabla-dinamica-autorizaciones.component.scss']
})
export class TablaDinamicaAutorizacionesComponent implements OnInit {

  @Input() columnas: Columns[];
  @Input() datos: any[];
  @Input() formatDate: string;
  @Input() actions: Actions[];
  @Input() nombreCabeceraActions: string;
  @Input() styleActions: string;
  @Input() sizePaginate: number;
  @Input() rows: number;
  @Input() paginator: boolean;
  /** Atrubuito que controla el paginado de la tabla */
  @Output() eventoPaginado: EventEmitter<Paginate> = new EventEmitter<Paginate>();
  /** Atributo que gestiona las acciones en la tabla */
  @Output() actionHandler: EventEmitter<Actions> = new EventEmitter<Actions>();
  /** Atributo que gestiona el cargue de información */
  @Output() actionCargarInformacion: EventEmitter<string> = new EventEmitter<string>();

  first = 0;

  /** Atributo que indica el número de página que debe ser consultado en el BackEnd */
  pageNumber = 0;

  /* Estados del semáforo de validación de documentos -> (Apoderado - Postor)  */
  estadoDocumento: EstadoValidacionDocumento[];

  constructor() {
    this.estadoDocumento = [
      { estado: 'Pendiente', colorSemaforo: ColorSemaforo.AMARILLO },
      { estado: 'No cumple', colorSemaforo: ColorSemaforo.ROJO },
      { estado: 'Cumple', colorSemaforo: ColorSemaforo.VERDE }
    ];
    //console.log('datos: ',this.datos);
  }

  ngOnInit(): void {
    this.formatDate = (this.formatDate === undefined) ? 'dd-MM-yyyy' : this.formatDate;
    this.nombreCabeceraActions = (this.nombreCabeceraActions === undefined) ? 'Acciones' : this.nombreCabeceraActions;
    this.sizePaginate = (this.sizePaginate === undefined) ? 100 : this.sizePaginate;
    this.rows = (this.rows === undefined) ? 10 : this.rows;
    this.paginator = (this.paginator === undefined) ? true : this.paginator;
  }

  /**
   * Método que se encarga de capturar el cambio de página de la tabla
   * @param evento Evento disparado cuando ocurre un cambio en el paginado
   */
  changePage(evento: any) {
    this.first = evento.first;
    const numeroRegistros = (this.datos.length - this.first);
    if (numeroRegistros === this.rows) {
      this.eventoPaginado.emit({
        pageNumber: (this.pageNumber + 1),
        pageSize: this.sizePaginate
      });
    }
  }

  /**
   * Retorna el identificador de la acción al componente principal
   * @param idAction Identificador de la acción
   * @param valueSelected Valor del registro seleccionado
   */
  sendActionHandler(idAction: string, valueSelected: any) {
    this.actionHandler.emit({
      id: idAction,
      valueSelected: String(valueSelected),
      icon: ''
    });
  }

  /**
   * Método que devuelve la action del cargue al componente principal
   * @param action Action cargue de información
   */
  cargarInformacion(action: string) {
    this.actionCargarInformacion.emit(action);
  }

  /**
   * Método que se encarga de darle estilo a la columna de validación del documento de acuerdo a su estado
   * @param estadoDocumento Estado de validación del documento
   */
  paintSemaforo(estadoDocumento: string): string {
    let colorSemaforo = '';
    switch (estadoDocumento) {
      case this.estadoDocumento[0].estado: // Pendiente
        colorSemaforo = this.estadoDocumento[0].colorSemaforo;
        break;
      case this.estadoDocumento[1].estado: // No cumple
        colorSemaforo = this.estadoDocumento[1].colorSemaforo;
        break;
      default: // Cumple
        colorSemaforo = this.estadoDocumento[2].colorSemaforo;
        break;
    }
    return colorSemaforo;
  }

} 
