import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  protected baseUrlApi: string;
  protected baseUrlAuth: string;
  protected  urlImage: string;

  constructor(protected http: HttpClient) {
    this.baseUrlApi = environment.urlApi;
    this.baseUrlAuth = environment.urlauth;
    this.urlImage = environment.urlImage;
  }

  protected getHttpHeaders(ignorarToken: boolean = false): HttpHeaders {
    const token: string = localStorage.getItem('token');
    if (token === null && ignorarToken === false) {
      throw new Error('Error No se encontro TOKEN');
    }
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Authorization', 'Bearer ' + token);
    return headers;
  }

  protected getHttpHeadersFile(): HttpHeaders {
    const token: string = localStorage.getItem('token');
    if (token === null) {
      throw new Error('Error No se encontro TOKEN');
    }
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + token);
    return headers;
  }


}
