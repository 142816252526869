<div class="row" #contenedorAudiencia>
  <div class="col-sm-12 col-md-12 col-lg-12 p-2">
    <p-dataView
      [value]="proximasAudiencias"
      [paginator]="true"
      [rows]="rows"
      [first]="first"
      emptyMessage=""
      (onPage)="changePage($event)"
      [alwaysShowPaginator]="false"
      styleClass="ng-dataview"
      [layout]="'grid'">
      <ng-template let-audi pTemplate="gridItem">
        <div class="p-col-12 p-md-3 p-lg-3">
          <div class="card">
            <div class="descuento">{{ audi.porcentajeLicitacion }}%</div>
            <p-galleria
              [(value)]="audi.consolidadoImagenes"
              [responsiveOptions]="responsiveOptions"
              [containerStyle]="{ 'max-width': '100%' }"
              [numVisible]="5"
              [circular]="true"
              class="ng-galleria"
              [showItemNavigators]="audi.consolidadoImagenes?.length > 1"
              [showThumbnails]="false"
              [showIndicators]="audi.consolidadoImagenes?.length > 1"
              [showItemNavigatorsOnHover]="true"
              [transitionInterval]="1000"
              [showIndicatorsOnItem]="true">
              <ng-template pTemplate="item" let-item>
                <img [src]="item" style="width: 100%; display: block; object-fit: cover; height: 250px;"/>
              </ng-template>
            </p-galleria>
            <div class="alert-primary m-2 p-1 border-0">
              {{ "postulacionesList.numeroRemate" | translate }}:
              <strong>{{ audi.numeroRemate }}</strong>
            </div>

            <div class="m-2 infocards">
              <h3>{{ audi.municipio }} - {{ audi.departamento }}</h3>
              {{ audi.descripcionBien }}
            </div>

            <div class="row m-2">
              <div class="col-6 indicadores m-0 p-0">
                <div>
                  {{ "postulacionesList.fechaAudiencia" | translate }}
                </div>
                <div>{{ audi.fechaAudiencia | date: "dd-MM-yyyy" }}</div>
              </div>
              <div class="col-6 indicadores m-0 p-0">
                <div>
                  {{ "postulacionesList.horaAudiencia" | translate }}
                </div>
                <div>{{ audi.horaAudiencia | date: "HH:mm" }}</div>
              </div>
            </div>

            <div class="indicadores m-2">
              <div>
                {{ "postulacionesList.avaluoBien" | translate }}:
                <strong>${{ formatValueThousand(audi.avaluoBien) }}</strong>
              </div>
            </div>

            <div class="oferta m-2">
              <div>{{ "postulacionesList.valorBase" | translate }}</div>
              <div>${{ formatValueThousand(audi.valorBaseOferta) }}</div>
            </div>

            <div class="m-2" (click)="verDetalle(audi)">
              <button class="btn w-100">
                {{ "postulacionesList.btnVer" | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </p-dataView>
  </div>
</div>
<!-- Spinner -->
<app-spinner [name]="name"
  [color]="'#323232'" [bdColor]="'rgba(255,255,255,0)'">
</app-spinner>

