import { TipoUsuario } from './../../services/util.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { BlobStorageConsulta } from 'src/app/protected/comun/model/blob-storage-consulta';
import { DataSendService } from 'src/app/protected/services/data-send.service';
import { RemateService } from 'src/app/remate/services/remates/remates.service';
import { BaseUtil } from '../../model/base-util';
import { RemateDetail } from '../../model/remate-detail';
import { SharedService } from '../../services/shared.service';
import { MenuNoFuncionario, UtilService } from '../../services/util.service';

@Component({
  selector: 'app-postulaciones-list',
  templateUrl: './postulaciones-list.component.html',
  styleUrls: ['./postulaciones-list.component.scss']
})
export class PostulacionesListComponent extends BaseUtil implements OnInit {

  /** Tipo de usuario logueado */
  @Input() tipoUsuarioValue: number;
  /** Especifica si los datos son cargados desde el padre o desde este componente */
  @Input() fromParent: boolean;
  @Input() postulaciones: RemateDetail[];
  /** Envía al componente padre la cantidad de remates a los que el usuario hizo postulaciones */
  @Output() sendCantidadRemates: EventEmitter<number> = new EventEmitter<number>();

  readonly name = 's-postulacion';

  responsiveOptions: any[] = [
    { breakpoint: '1024px', numVisible: 5 },
    { breakpoint: '768px', numVisible: 3 },
    { breakpoint: '560px', numVisible: 1 }
  ];

  images: string[] = [];

  consulta: BlobStorageConsulta;

  constructor(protected translateService: TranslateService, protected lang: LanguageService,
              protected spinner: NgxSpinnerService, private remateService: RemateService,
              protected utilService: UtilService, private router: Router,
              protected sharedService: SharedService, private dataSendService: DataSendService) {
    super(translateService, lang, sharedService, utilService, spinner);
  }

  ngOnInit(): void {
    this.postulaciones = [];
    this.fromParent = (this.fromParent === undefined) ? false : this.fromParent;
    this.initFilter(this.tipoUsuarioValue, false);
    this.loadRematesPostulaciones();
  }

  /** Método que se encarga de listar las postulaciones hechas por el usuario logueado */
  private loadRematesPostulaciones() {
    if (!this.fromParent) {
      setTimeout(() => {
        this.spinner.show(this.name);
        this.searchFilter.paginado = this.getPaginate(0, 4);
        //Mantis: 408
        this.searchFilter.applyDistinctOnRecords = true;
        this.remateService.findRematesPostulaciones(this.searchFilter).subscribe(pos => {
          this.postulaciones = pos;
          this.setImagenes();
          this.sendCantidadRemates.emit(this.postulaciones.length);
        }, err => {
          console.error(err);
          this.spinner.hide(this.name);
        }, () => this.spinner.hide(this.name));
      }, 2000);
    }
  }

  /**
   * Método que se encarga de asignarle los separadores de miles al valor especificado
   * @param valor Valor del avalúo o valor base de la oferta
   */
  formatValueThousand(valor: any): string {
    return this.utilService.formatValueThousand(valor);
  }

  /**
   * Método que redirecciona al detalle
   * @param postulacion Datos de la postulación
   */
  verDetalle(postulacion: RemateDetail) {
    this.dataSendService.setData({
      data: Array.of(postulacion.idAuto),
      componente: MenuNoFuncionario.POSTULACIONES_INICIO,
      redirectInicio: '/protected/mispostulaciones',
      idPostulacion: postulacion.idPostulacion,
      numeroRemate: postulacion.numeroRemate
    });
    this.router.navigate(['/protected/rematesDetalle']);
  }

  /** Método que se encarga de asignar las imágenes a las tarjetas de presentación del remate */
  private setImagenes() {
    this.consulta = {  nroRadicado: 52631000042357, usuarioSistema: ''};
    for (const rem of this.postulaciones) {
      rem.consolidadoImagenes = [];
      for (const bien of rem.bienes) {
        for (const img of bien.imagenes) {
          this.consulta.nroRadicado = img.metadata.numeroRadicado;
          this.consulta.usuarioSistema = this.dataSendService.getValueItem('usuarioSistema');
          this.remateService.consultaRadicado(this.consulta).subscribe(pic => {
            img.file = 'data:image/jpeg;base64,' + pic.body;
            rem.consolidadoImagenes.push(img.file);
          }, err => console.error(err));
        }
      }
    }
  }

  /**
   * Mantis incidencia: 0000665
   */
  public esRolApoderado(): boolean{
    return this.tipoUsuarioValue === TipoUsuario.APODERADO;
  }

}
