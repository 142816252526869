<p-toast></p-toast>

<div class="card">
  <p-table #dt [value]="documents" [rows]="10" [paginator]="true"
           *ngIf="showDocuments"
           [(selection)]="selectedDocuments" [rowHover]="true"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [showCurrentPageReport]="true">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button pButton pRipple label="{{'file.nuevo' | translate}}" icon="pi pi-plus" class="p-button-success p-mr-2"
                (click)="openNewDocuments()"></button>
        <button pButton pRipple label="{{'file.eliminar' | translate}}" icon="pi pi-trash" class="p-button-warning p-mr-2"
                (click)="deleteSelectedDocuments()"
                [disabled]="!selectedDocuments || !selectedDocuments.length"></button>
        <button pButton pRipple label="{{'file.guardar' | translate}}" icon="pi pi-upload" class="p-button-info p-ml-auto"
                (click)="saveFiles()"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>{{'file.nroRadicado' | translate}}</th>
        <th>{{'file.nombreArchivo' | translate}}</th>
        <th>{{'file.descripcion' | translate}}</th>
        <th>{{'file.totalFolios' | translate}}</th>
        <th style="width: 25rem"></th>
        <th style="width: 3rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-document>
      <tr>
        <td>
          <p-tableCheckbox [value]="document"></p-tableCheckbox>
        </td>
        <td>{{document.nroRadicado}}</td>
        <td>{{document.nombreArchivo}}</td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="document.descripcion">
            </ng-template>
            <ng-template pTemplate="output">
              {{document.descripcion}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="document.totalFolios">
            </ng-template>
            <ng-template pTemplate="output">
              {{document.totalFolios}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <p-fileUpload name="myfile[]"
                        chooseLabel="{{'file.examinar' | translate}}"
                        customUpload="true"
                        accept="{{filetype}}"
                        maxFileSize="{{maxFileSize}}"
                        (uploadHandler)="onUpload($event, document)"
          ></p-fileUpload>
        </td>
        <td>
          <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                  (click)="deleteDocument(document)"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        In total there are {{documents ? documents.length : 0 }} documents.
      </div>
    </ng-template>
  </p-table>

  <br>

  <p-table #dt [value]="images" [rows]="10" [paginator]="true"
           *ngIf="showImages"
           [(selection)]="selectedImages" [rowHover]="true"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
           [showCurrentPageReport]="true">
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button pButton pRipple label="{{'file.nuevo' | translate}}" icon="pi pi-plus" class="p-button-success p-mr-2"
                (click)="openNewImages()"></button>
        <button pButton pRipple label="{{'file.eliminar' | translate}}" icon="pi pi-trash" class="p-button-warning p-mr-2"
                (click)="deleteSelectedImages()"
                [disabled]="!selectedImages || !selectedImages.length"></button>
        <button pButton pRipple label="{{'file.guardar' | translate}}" icon="pi pi-upload" class="p-button-info p-ml-auto"
                (click)="saveFiles()"></button>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th style="width: 4rem">{{'file.nroRadicado' | translate}}</th>
        <th style="width: 4rem">{{'file.nombreImagen' | translate}}</th>
        <th style="width: 10rem"></th>
        <th style="width: 1rem"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-img>
      <tr>
        <td>
          <p-tableCheckbox [value]="img"></p-tableCheckbox>
        </td>
        <td>{{img.nroRadicado}}</td>
        <td>{{img.nombreImagen}}</td>
        <td>
          <p-fileUpload name="file[]"
                        chooseLabel="{{'file.examinar' | translate}}"
                        customUpload="true"
                        accept="{{filetype}}"
                        maxFileSize="{{maxFileSize}}"
                        (uploadHandler)="onUploadImages($event, img)"
          ></p-fileUpload>
        </td>
        <td>
          <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning"
                  (click)="deleteImage(img)"></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="summary">
      <div class="p-d-flex p-ai-center p-jc-between">
        In total there are {{images ? images.length : 0 }} images.
      </div>
    </ng-template>
  </p-table>
</div>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
