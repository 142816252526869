import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-info',
  templateUrl: './footer-info.component.html',
  styleUrls: ['./footer-info.component.scss']
})
export class footerInfoComponent {

  constructor() {
  }

}
