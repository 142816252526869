import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss']
})
export class ModalSuccessComponent implements OnInit {

  /** Muestra u oculta la modal */
  viewModalMessage: boolean;
  /** Nombre del botón que aparece en la modal */
  nameBtnModal: string;
  /** Icono de la modal */
  icono: string;
  /** Titulo de la modal */
  titleModal: string;
  /** Redirección por URL al componente especificado */
  @Input() redirect: string;
  /** Redirección de vista. Se usa cuando no es posible acceder por URL */
  @Output() redirectView: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** Descripcion adicional **/
  errors: string[] = undefined;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.viewModalMessage = undefined;
    this.icono = (this.icono === undefined) ? 'assets/images/iconos-secundarios/exito.svg' : this.icono;
    this.nameBtnModal = 'Salir';
    this.titleModal = 'Success';
    this.errors = [];
  }

  /** Método que cierra la modal */
  complete() {
    this.viewModalMessage = false;
    if (this.redirect !== undefined) {
      this.router.navigate([this.redirect]);
    } else {
      this.redirectView.emit(true);
    }
  }

  addError(error: string){
    if(this.errors === undefined || this.errors === null){
      this.errors = [];
    }
    this.errors.push(error);
  }

}
