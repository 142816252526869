import { FileDTO } from './file-dto';
/**
 * Clase que almacena el archivo con su metadata
 */
export class FilesLoad {

  /**
   * Constructor de la clase `FilesLoad`
   * @param file Archivo convertido en Base64
   * @param metadata Metadata del archivo
   */
  constructor(
    public file: string,
    public metadata: FileDTO
  ) {}

}
