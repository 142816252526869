import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {FileService} from '../../services/file.service';
import {DocumentModel} from '../../model/DocumentModel';
import {ImageModel} from '../../model/ImageModel';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../../core/services/language/language.service';

@Component({
  selector: 'app-file-svr',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css'],
  styles: [`
    :host ::ng-deep .p-dialog .Document-image {
      width: 150px;
      margin: 0 auto 2rem auto;
      display: block;
    }
  `],
  providers: [MessageService, ConfirmationService]
})
export class FileComponent implements OnInit {

  showDocuments: boolean;

  documents: DocumentModel[];

  document: DocumentModel;

  showImages: boolean;

  selectedDocuments: DocumentModel[];

  images: ImageModel[];

  image: ImageModel;

  selectedImages: ImageModel[];

  submitted: boolean;

  statuses: any[];

  filetype = 'application/pdf';

  maxFileSize = 4000000;

  btnNew: string;

  responsiveOptions:any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  responsiveOptions2:any[] = [
    {
      breakpoint: '1500px',
      numVisible: 5
    },
    {
      breakpoint: '1024px',
      numVisible: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  displayBasic: boolean;

  displayBasic2: boolean;

  displayCustom: boolean;

  activeIndex: number = 0;

  constructor(private fileService: FileService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private translate: TranslateService,
              private lang: LanguageService,) {
  }

  ngOnInit() {
    try {
      // Setea el lenguaje del componente obtenienido del browser
      this.translate.setDefaultLang(this.lang.browserLanguage());
      //this.filetype = 'image/*';
      this.showDocuments = false;
      this.showImages = false;
      this.documents = [];
      this.images = [];
      this.loadDocumentsAuto();
      this.loadDocumentsAutoriz();
      this.loadImagesBien();
      this.openNew();
    } catch (error) {
      console.error('Error message', error);
    }
  }

  loadDocumentsAuto() {

    let idAuto: string = localStorage.getItem('idAuto');

    if (!idAuto) {
      return;
    }

    const auto = parseInt(idAuto);

    this.fileService.getDocumentsByIdAuto(auto).subscribe(
      (result: any) => {

        if (result === null || result.length === 0) {
          this.openNew();
        } else {
          this.documents = result;
        }

        this.showDocuments = true;

      }, error => {
        console.error(error);
      });

  }

  loadDocumentsAutoriz() {

    let idAutoriz: string = localStorage.getItem('idAutoriz');

    if (!idAutoriz) {
      return;
    }

    const auto = parseInt(idAutoriz);

    this.fileService.getDocumentsByIdAutorizacion(auto).subscribe(
      (result: any) => {

        if (result === null || result.length === 0) {
          this.openNew();
        } else {
          this.documents = result;
        }

        this.showDocuments = true;

      }, error => {
        console.error(error);
      });

  }

  loadImagesBien() {

    let idBien: string = localStorage.getItem('idBien');

    if (!idBien) {
      return;
    }

    const bien = parseInt(idBien);

    this.fileService.getImagesByIdBien(bien).subscribe(
      (result: any) => {

        if (result === null || result.length === 0) {
          this.openNew();
        } else {
          this.images = result;
        }

        this.filetype = 'image/*';
        this.showImages = true;

      }, error => {
        console.error(error);
      });

  }

  openNew() {
    if (this.documents.length == 0) {
      const document = new DocumentModel();
      document.id = this.createId();
      this.documents.push(document);
    }
    if (this.images.length == 0) {
      const image = new ImageModel();
      image.id = this.createId();
      this.images.push(image);
    }
  }

  openNewDocuments() {
    const document = new DocumentModel();
    document.id = this.createId();
    this.documents.push(document);
  }

  openNewImages() {
    const image = new ImageModel();
    image.id = this.createId();
    this.images.push(image);
  }

  deleteSelectedDocuments() {
    try {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Documents?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.documents = this.documents.filter(val => !this.selectedDocuments.includes(val));
          this.selectedDocuments = null;
          this.messageService.add({severity: 'success', summary: 'Successful',
            detail: this.translate.instant('file.archivoseliminado'), life: 3000});
        }
      });
    } catch (error) {
      console.error('Error message', error);
    }
  }

  deleteDocument(document: DocumentModel) {
    try {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + document.nombreArchivo + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if (document.idDocumento) {
            this.documents = this.documents.filter(val => val.idDocumento !== document.idDocumento);
            this.messageService.add({severity: 'success', summary: 'Successful',
              detail: this.translate.instant('file.archivoeliminado'), life: 3000});
            return;
          }
          this.documents = this.documents.filter(val => val.id !== document.id);
          this.messageService.add({severity: 'success', summary: 'Successful',
            detail: this.translate.instant('file.archivoeliminado'), life: 3000});
        }
      });

    } catch (error) {
      console.error('Error message', error);
    }
  }

  deleteSelectedImages() {
    try {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete the selected Documents?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.images = this.images.filter(val => !this.selectedImages.includes(val));
          this.selectedImages = null;
          this.messageService.add({severity: 'success', summary: 'Successful',
            detail: this.translate.instant('file.archivoseliminado'), life: 3000});
        }
      });
    } catch (error) {
      console.error('Error message', error);
    }
  }

  deleteImage(image: ImageModel) {
    try {
      this.confirmationService.confirm({
        message: 'Are you sure you want to delete ' + image.nombreImagen + '?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if (image.idImagen) {
            this.images = this.images.filter(val => val.idImagen !== image.idImagen);
            this.messageService.add({severity: 'success', summary: 'Successful',
              detail: this.translate.instant('file.archivoeliminado'), life: 3000});
            return;
          }
          this.images = this.images.filter(val => val.id !== image.id);
          this.messageService.add({severity: 'success', summary: 'Successful',
            detail: this.translate.instant('file.archivoeliminado'), life: 3000});
        }
      });

    } catch (error) {
      console.error('Error message', error);
    }
  }

  saveFiles() {
    try {
      if (this.showDocuments) {
        localStorage.setItem('documents', JSON.stringify(this.documents));
      }
      if (this.showImages) {
        localStorage.setItem('images', JSON.stringify(this.images));
      }

      this.messageService.add({
        severity: 'info',
        summary:  this.translate.instant('file.cargados'),
        detail: ''
      });
    } catch (error) {
      console.error('Error message', error);
    }
  }

  onUpload(event, document: DocumentModel) {
    try {
      if (!event.files) {
        return;
      }

      const file = event.files[0];

      document.nombreArchivo = file.name;
      document.descripcion = file.name;
      document.nroRadicado = 0;
      document.totalFolios = 1;

      if (document.id) {
        this.documents[this.findIndexById(document.id, this.documents)] = document;
      }
      if (document.idDocumento) {
        this.documents[this.findIndexByIdDocumento(document.idDocumento)] = document;
      }

      this.messageService.add({
        severity: 'info',
        summary: 'Files Uploades',
        detail: ''
      });
    } catch (error) {
      console.error('Error message', error);
    }
  }

  onUploadImages(event, image: ImageModel) {
    try {
      if (!event.files) {
        return;
      }

      const file = event.files[0];
      image.imagenes = [];
      image.nombreImagen = file.name;
      image.nroRadicado = 0;
      let img = new ImageModel();
      let path = file.objectURL.changingThisBreaksApplicationSecurity;
      path = path.replace('blob:','');
      img.previewImageSrc = path;
      image.imagenes.push(img);

      if (image.id) {
        this.images[this.findIndexById(image.id, this.images)] = image;
      }
      if (image.idImagen) {
        this.images[this.findIndexByIdImagen(image.idImagen)] = image;
      }

      this.messageService.add({
        severity: 'info',
        summary: 'Files Saves',
        detail: ''
      });
    } catch (error) {
      console.error('Error message', error);
    }
  }


  findIndexById(id: string, lista: any): number {
    let index = -1;
    for (let i = 0; i < lista.length; i++) {
      if (lista[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  findIndexByIdDocumento(idDocumento: number): number {
    let index = -1;
    for (let i = 0; i < this.documents.length; i++) {
      if (this.documents[i].idDocumento === idDocumento) {
        index = i;
        break;
      }
    }

    return index;
  }

  findIndexByIdImagen(idImagen: number): number {
    let index = -1;
    for (let i = 0; i < this.images.length; i++) {
      if (this.images[i].idImagen === idImagen) {
        index = i;
        break;
      }
    }

    return index;
  }

  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }
}
