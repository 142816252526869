import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private setLang$: Subject<string> = new Subject();

  constructor() {

  }

  public setLang(text) {
    return this.setLang$.next(text);
  }

  public onSetLang() {
    return this.setLang$.asObservable();
  }

  browserLanguage() {
    return navigator.language.substring(0, 2);
  }

}
