import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {throwError} from 'rxjs/internal/observable/throwError';
import {BaseService} from '../../../core/services/base.service';

/*
 * PDF SERVICE
 */
@Injectable({
  providedIn: 'root'
})
export class PdfService extends BaseService {

  controlExcepcion(error: HttpErrorResponse) {
    return throwError(error);
  }

  /**
   * test del servicio
   */
  public test(): void {
  }

  /**
   * return BLOB STORAGE PDF
   *
   */
  public radicarPdf(file: string, nombreArchivo: string,
                    tipoArchivo: string): Observable<any> {
    if (!file) {
      throw new Error('Error file es obligatorio');
    }
    if (!nombreArchivo) {
      throw new Error('Error nombreArchivo es obligatorio');
    }
    const url = this.baseUrlApi + '/v1/common/radicarDocumento';
    const body = {
      base64: file,
      nombre: nombreArchivo,
      tipo: tipoArchivo
    };
    return this.http.post(url, body, {headers: this.getHttpHeaders()});
  }

  /**
   * return BLOB STORAGE PDF
   *
   */
  public consultarPdf(radicado: number): Observable<any> {
    if (!radicado) {
      throw new Error('Error el radicado es obligatorio');
    }
    const usuarioSis = localStorage.getItem('usuarioSistema');
    // tslint:disable-next-line:radix
    if (!usuarioSis) {
      throw new Error('Error no se encontro en el localstorage: usuarioSistema');
    }
    const url = this.baseUrlApi + '/v1/common/documentoByNumeroRadicado';
    const body = {
      nroRadicado: radicado,
      usuarioSistema: usuarioSis
    };
    return this.http.post(url, body, {headers: this.getHttpHeaders()});
  }

  /**
   * return BLOB STORAGE PDF
   *
   * No timeout
   *
   */
  public consultarPdfInvitado(radicado: number): Observable<any> {
    if (!radicado) {
      throw new Error('Error el radicado es obligatorio');
    }
    const usuarioSis = localStorage.getItem('usuarioSistema');
    // tslint:disable-next-line:radix
    if (!usuarioSis) {
      throw new Error('Error no se encontro en el localstorage: usuarioSistema');
    }
    const url = this.baseUrlApi + '/v1/common/getDocumentoByNumeroRadicadoInvitado';
    const body = {
      nroRadicado: radicado,
      usuarioSistema: usuarioSis
    };
    return this.http.post(url, body, {headers: this.getHttpHeaders()});
  }

  /**
   * retorna a partir de base64 un Blob par mostrar le archivo
   *  base64
   *  fileType
   */
  public base64ToPDF(base64: string, contentType: string): Blob {
    const blobFileArray = Uint8Array.from(atob(base64), x => x.charCodeAt(0));
    const blobPDF = new Blob(Array.of(blobFileArray), { type: contentType });

    if (!blobPDF) {
      throw new Error('Error generando Archivo PDF');
    }
    return blobPDF;
  }


}
