import { Component, EventEmitter, Input, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnyNaptrRecord } from 'dns';
import { Table } from 'primeng';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { TranslateUtilsPipe } from 'src/app/pipe/translate-utils.pipe';
import { Actions } from 'src/app/remate/model/actions';
import { Columns } from 'src/app/remate/model/columns';
import { Paginate } from 'src/app/remate/model/paginate';
import { ColorSemaforo, EstadoValidacionDocumento } from './model/estado-validacion-documento';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  providers: [TranslateUtilsPipe]
})
export class DynamicTableComponent implements OnInit {

  @Input() columnas: Columns[];
  @Input() datos: any[];
  @Input() formatDate: string;
  @Input() actions: Actions[];
  @Input() nombreCabeceraActions: string;
  @Input() styleActions: string;
  @Input() sizePaginate: number;
  @Input() rows: number;
  @Input() paginator: boolean;
  @Input() viewActions: boolean;
  @Input() totalRecords: number;
  @Input() lazy: false;
  /** Atrubuito que controla el paginado de la tabla */
  @Output() eventoPaginado: EventEmitter<Paginate> = new EventEmitter<Paginate>();
  /** Atributo que gestiona las acciones en la tabla */
  @Output() actionHandler: EventEmitter<Actions> = new EventEmitter<Actions>();
  /** Atributo que gestiona el cargue de información */
  @Output() actionCargarInformacion: EventEmitter<any> = new EventEmitter<any>();
  /** Atributo que gestiona el cargue de información diferida del componente p-table*/
  @Output() onLazyLoad: EventEmitter<any> = new EventEmitter<any>();

  first = 0;

  /** Atributo que indica el número de página que debe ser consultado en el BackEnd */
  pageNumber = 0;

  /* Estados del semáforo de validación de documentos -> (Apoderado - Postor)  */
  estadoDocumento: EstadoValidacionDocumento[];
  estadoDocumentoIds: EstadoValidacionDocumento[];

  /** Atributo que determina si se muestra icono de proceso del componente p-table*/
  @Input() loading: boolean;

  constructor(
    private translate: TranslateService,
    private lang: LanguageService,
    private translateUtilsPipe: TranslateUtilsPipe) {
    this.estadoDocumento = [
      { estado: 'Pendiente', colorSemaforo: ColorSemaforo.AMARILLO },
      { estado: 'No cumple', colorSemaforo: ColorSemaforo.ROJO },
      { estado: 'Cumple', colorSemaforo: ColorSemaforo.VERDE }
    ];
    this.estadoDocumentoIds = [
      { estado: 36, colorSemaforo: ColorSemaforo.AMARILLO },
      { estado: 37, colorSemaforo: ColorSemaforo.ROJO },
      { estado: 38, colorSemaforo: ColorSemaforo.VERDE }
    ];
  }

  ngOnInit(): void {
    this.translate.setDefaultLang(this.lang.browserLanguage());
    this.formatDate = (this.formatDate === undefined) ? 'dd-MM-yyyy' : this.formatDate;
    this.nombreCabeceraActions = (this.nombreCabeceraActions === undefined) ? 'dynamicTable.actions' : this.nombreCabeceraActions;
    this.sizePaginate = (this.sizePaginate === undefined) ? 100 : this.sizePaginate;
    this.rows = (this.rows === undefined) ? 10 : this.rows;
    this.paginator = (this.paginator === undefined || this.paginator === null) ? true : this.paginator;
    this.viewActions = (this.viewActions === undefined) ? true : this.viewActions;
    this.totalRecords = (this.totalRecords === undefined) ? 0 : this.totalRecords;
    this.lazy = (this.lazy === undefined) ? false : this.lazy;
    this.loading = (this.loading === undefined || this.loading === null) ? false : this.loading;
  }

  /**
   * Método que se encarga de capturar el cambio de página de la tabla
   * @param evento Evento disparado cuando ocurre un cambio en el paginado
   */
  changePage(evento: any) {
    this.first = evento.first;
    const numeroRegistros = (this.datos.length - this.first);
    if (numeroRegistros === 1) {
      this.eventoPaginado.emit({
        pageNumber: (this.pageNumber + 1),
        pageSize: this.sizePaginate
      });
    }
  }

  /**
   * Retorna el identificador de la acción al componente principal
   * @param action Objeto de la acción seleccionada
   * @param rowData Objeto completo de la tabla
   */
  sendActionHandler(action: Actions, rowData: any) {
    if (!action.isActive) { return; }
    let value: any;
    if (action.nameAttributeModel === 'object') {
      value = rowData;
    } else {
      value = rowData[action.nameAttributeModel];
    }
    this.actionHandler.emit({
      id: action.id,
      valueSelected: value,
      icon: ''
    });
  }

  /**
   * Método que devuelve la action del cargue al componente principal
   * @param rowData Información del registro seleccionado
   */
  cargarInformacion(rowData: any) {
    this.actionCargarInformacion.emit(rowData);
  }

  /**
   * Metodo que captura el evento de carga diferida en el componente p-table
   * @param event
   */
  onLazyLoadEvent(event) {
    this.onLazyLoad.emit(event);
  }

  /**
   * Método que se encarga de darle estilo a la columna de acuerdo a su estado
   * @param estado Estado
   */
  paintSemaforo(estado: any): string {
    let colorSemaforo = '';
    //Verificacion de estados en documentos
    colorSemaforo = this.getColorSemaforoEstadoDocumentos(estado);
    if (colorSemaforo == '') {
      //Si no encontro algun valor, verifica en los estados de la audiencia
      colorSemaforo = this.getColorSemaforoEstadoAudiencia(estado);
    }
    return colorSemaforo;
  }

  /**
   * Determina el style del semaforo a aplicar si el estado coincide con alguno de los contemplados para documentos
   * @param estado 
   */
  private getColorSemaforoEstadoDocumentos(estado: any): string {
    if (this.estadoDocumento[0].estado == estado) {// Pendiente
      return this.estadoDocumento[0].colorSemaforo;
    } else if (this.estadoDocumentoIds[0].estado == estado) {// ESTADO_DOCUMENTO_SIN_VALIDAR
      return this.estadoDocumentoIds[0].colorSemaforo;
    } else if (this.estadoDocumento[1].estado === estado) {// No cumple
      return this.estadoDocumento[1].colorSemaforo;
    } else if (this.estadoDocumentoIds[1].estado == estado) {// ESTADO_DOCUMENTO_NO_CUMPLE
      return this.estadoDocumentoIds[1].colorSemaforo;
    } else if (this.estadoDocumento[2].estado == estado) {// Cumple
      return this.estadoDocumento[2].colorSemaforo;
    } else if (this.estadoDocumentoIds[2].estado == estado) {// ESTADO_DOCUMENTO_CUMPLE
      return this.estadoDocumentoIds[2].colorSemaforo;
    }
    return '';
  }

  /**
  * Determina el style del semaforo a aplicar si el estado coincide con alguno de los contemplados para audiencias
  * @param estado 
  */
  private getColorSemaforoEstadoAudiencia(estado: any): string {
    if (estado == 'Finalizada') {
      return '';
    } else if (estado == 'En curso') {
      return ColorSemaforo.VERDE;
    } else if (estado == 'Pendiente') {
      return ColorSemaforo.AMARILLO;
    } else if (estado == 'Cancelada') {
      return ColorSemaforo.ROJO;
    } else if (estado == 'Reanudada') {
      return '';
    } else if (estado == 'Desierta') {
      return '';
    } else if (estado == 'Inválida') {
      return '';
    } else if (estado == 'Suspendida') {
      return ColorSemaforo.GRIS;
    }
  }

  /**
   * Traduce los estados de los documentos
   * @param rowData
   * @param field
   * @returns
   */
  public traducirEstadoValidacion(rowData, field) {
    let value = this.resolve(field, rowData);
    return this.translateUtilsPipe.transform(value, 'estadoValidacionDocumento');
  }

  /**
   * Traducir los estados de las audiencias
   * @param rowData
   * @param field
   */
  public traducirEstadoAudiencia(rowData, field) {
    let value = rowData[field];
    return this.translateUtilsPipe.transform(value, 'estadoAudiencia');
  }

  /**
   * Determina si se debe habilitar o no el link de la columna cargar informacion
   * @param rowData 
   * @returns 
   */
  public enableLinkCargarInformacion(rowData: any) {
    let subsana = rowData['subsana'] !== undefined && rowData['subsana'];
    let cargaInfoNoCumple = rowData['cargaInfoNoCumple'];
    let enableLink = rowData['enableLink'];
    return (subsana && cargaInfoNoCumple) || enableLink
  }

  /**
   * Obtiene el valor a ser visualizado
   * @param rowData 
   * @param field 
   * @returns 
   */
  public getDataPreview(rowData, field) {
    var value = this.resolve(field, rowData);
    return value;
  }

  /**
   * Recupera el valor acorde a un string path en el objeto
   * @param stringPath 
   * @param baseObject 
   * @returns 
   */
  private resolve(stringPath, baseObject): any {
    return stringPath.split('.').reduce((p, q) => {
      return p ? p[q] : null;
    }, baseObject || self);
  }

}
