import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BaseService } from 'src/app/core/services/base.service';


@Injectable({
  providedIn: 'root'
})
export class SharedService extends BaseService {

  private resourceUrl: string;

  getAllDepartamentos(): Observable<any> {
    return this.http.get(this.baseUrlApi + '/remate/departamentos/', { headers: this.getHttpHeaders() });
  }

  getAllMunicipios(id: number): Observable<any> {
    return this.http.get(this.baseUrlApi + '/remate/municipios/' + id, { headers: this.getHttpHeaders() });
  }

  getAllDominio(id): Observable<any> {
    return this.http.get(this.baseUrlApi + '/remate/dominio/' + id, { headers: this.getHttpHeaders() });
  }

  getAllZonas(): Observable<any> {
    return this.http.get(this.baseUrlApi + '/v1/remate/bienes/listzona', { headers: this.getHttpHeaders() });
  }

  getAllDominioPorNombre(nombreDominio: string): Observable<any> {
    return this.http.post(this.baseUrlApi + '/v1/common/findDominiosPorNombre', nombreDominio, {headers: this.getHttpHeaders()});
  }

  /**
   * Permite obtener informacion de la dian
   */
  getInformacionDian():Observable<any>{
    return this.http.get(this.baseUrlApi + '/v1/common/parametros/informacion/dian', { headers: this.getHttpHeaders() });
  }

  /**
   * Permite obtener informacion de las seccionales
   */
  consultarSeccionales():Observable<any>{
    return this.http.get(this.baseUrlApi + '/v1/common/parametros/informacion/seccionales', { headers: this.getHttpHeaders() });
  }

  getAllFuncionario(): Observable<any> {
    return this.http.get(this.baseUrlApi + '/v1/common/funcionario/consultar', {headers: this.getHttpHeaders()});
  }

}
