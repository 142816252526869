import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng';
import {MenuService} from '../../../protected/services/menu/menu.service';
import {MenuSvr} from '../../../protected/model/menu.svr';

@Component({
  selector: 'app-menu',
  templateUrl: 'menu.component.html',
  styleUrls: ['menu.component.css']
})
export class MenuComponent implements OnInit {

  items: Array<MenuItem> = [];

  constructor(private menuService: MenuService) {
  }

  ngOnInit() {
    this.getMenus();
  }


  /**
   * login page
   */
  private getMenus(): void {
    let menus: MenuSvr[];
    this.menuService.getMenuUsuario().subscribe(
      (result: any) => {
        menus = result;
        if (menus === null) {
          throw new Error('Error res NULL');
        }
        if (menus === null) {
          throw new Error('Error MENU NULL');
        }

        for (const menu of menus) {
          this.items.push(menu);
        }

      }, error => {
        console.error(error);
      });
  }

}
