import { RemateDetail } from './../../../shared/model/remate-detail';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Remate } from '../../model/remate';
import { BaseService } from 'src/app/core/services/base.service';
import { SearchModel } from 'src/app/shared/model/searchModel';
import { FiltroRematesAsignados } from 'src/app/protected/remates-asignados-postulacion/model/filtro-remates-asignados';
import { FiltroPostorAsignado } from 'src/app/protected/remates-asignados-postulacion/model/filtro-postor-asignado';
import { CantRemateApod } from 'src/app/protected/remates-asignados-postulacion/model/cant-remate-apod';
import { AutorizacionDTO } from 'src/app/protected/nueva-autorizacion/model/autorizacionDto';
import { Page } from 'src/app/protected/nueva-autorizacion/nueva-autorizacion.component';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UsuarioRutDTO } from 'src/app/protected/nueva-autorizacion/model/usuarioRutDto';
import { RemateCard } from '../../model/remate-card';
import { FiltroBienesRemate } from 'src/app/protected/remates/inicio/model/filtro-bienes-remate';
import { PermisoApoderadoDTO } from 'src/app/core/model/permiso-apoderado-dto';
import { AutorizaApoderadoDTO } from 'src/app/core/model/autoriza-apoderado-dto';


@Injectable({
  providedIn: 'root'
})
export class RemateService extends BaseService {
  private myDate = new Date();
  remates: Remate[] = [];

  // constructor(
  // private http: HttpClient
  // ) { }

  getAllRemates(): Observable<any> {
    return this.http.get(this.baseUrlApi + '/v1/revautos/list', { headers: this.getHttpHeaders() });
  }

  getAllRematesCount(): Observable<string> {
    const url = this.baseUrlApi + '/v1/revautos/list/count';
    return this.http.get<string>(url, { headers: this.getHttpHeaders() });
  }

  getAllRematesPaginate(page: number, size: number): Observable<any> {
    let url = this.baseUrlApi + '/v1/revautos/list';
    url += "/" + page + "/" + size;
    return this.http.get(url, { headers: this.getHttpHeaders() });
  }

  getSearchFilter(criteria: SearchModel): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.get(this.baseUrlApi + '/v1/revautos/search/' + criteria.tipoBienes.id + '/' + criteria.tipoInmuebles.id + '/' + criteria.departamentos.idDepartamento + '/' + criteria.municipios.idMunicipio + '/' + criteria.valorDesde + '/' + criteria.valorHasta + '/' + criteria.fechaInicioData + '/' + criteria.fechaFinData + '/' + criteria.orderBy, { headers: this.getHttpHeaders() });
  }
 /* getAllRematesUser(idUsuario): Observable<any> {
    return this.http.get(this.baseUrlApi + '/v1/revautos/RematesUser' + idUsuario, { headers: this.getHttpHeaders() });
  }*/
  getSearchFilterWord(criteria: SearchModel): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.get(this.baseUrlApi + '/v1/revautos/word/' + criteria.forWord, { headers: this.getHttpHeaders() });
  }

  getAll(): Observable<any> {
    return this.http.get(this.baseUrlApi + '/v1/revautos/findAll', { headers: this.getHttpHeaders() });
  }

  save(remate: Remate): Observable<any> {
    return this.http.post(this.baseUrlApi + '/v1/revautos/save', JSON.stringify(remate),
      { headers: this.getHttpHeaders() });
  }

  getComments(): Observable<any> {
    return this.http.get('https://jsonplaceholder.typicode.com/comments');
  }

  findFilters(data: any): Observable<any> {
    return this.http.post(this.baseUrlApi + '/v1/revautos/search', JSON.stringify(data),
      { headers: this.getHttpHeaders() });
  }

  findFiltersReporteGestion(data: any): Observable<any> {
    return this.http.post(this.baseUrlApi + '/v1/revautos/reporteGestionBuscar', JSON.stringify(data),
      { headers: this.getHttpHeaders() });
  }

  /**
   * EndPoint quue se encarga de listar todos los remates asignados con o sin filtros
   * @param body Datos necesarios para hacer el filtro de los remates asignados al postor
   */
  findAllRematesAsignados(body: FiltroRematesAsignados): Observable<CantRemateApod[]> {
    return this.http.post<CantRemateApod[]>(
      this.baseUrlApi + '/v1/noFuncionario/findAllRematesApoderados', body,
      { headers: this.getHttpHeaders() });
  }

  /**
   * EndPoint quue se encarga de listar todos los remates asignados con o sin filtros
   * @param body Datos necesarios para hacer el filtro de los remates asignados al postor
   */
   findAllRematesAsignadosApoderado(body: FiltroPostorAsignado): Observable<CantRemateApod[]> {
    return this.http.post<CantRemateApod[]>(
      this.baseUrlApi + '/v1/noFuncionario/findAllRematesApoderadosPostores', body,
      { headers: this.getHttpHeaders() });
  }

  remateActivosSinPostulacionRevAutos(estadoRegistro: number): Observable<any> {
    return this.http.get(this.baseUrlApi + '/v1/revautos/remateActivosSinPostulacion/' + estadoRegistro,  { headers: this.getHttpHeaders() });
  }

  remateActivosSinPostulacionAutorizaciones(estadoRegistro: number): Observable<any> {
    return this.http.get(this.baseUrlApi + '/v1/autorizaciones/remateActivosSinPostulacion/' + estadoRegistro,  { headers: this.getHttpHeaders() });
  }

  getDataPage(usuarioRutDTO : UsuarioRutDTO, busqueda: HttpParams): Observable<Page[]> {
    let enlace = this.baseUrlApi + '/v1/autorizaciones/remateActivosSinPostulacionPaginador';
    return this.http.post<Page[]>(enlace, usuarioRutDTO, {headers: this.getHttpHeaders(),params: busqueda}).pipe(retry(2), catchError(this.errorHandler));;
  }

  remateActivosSinPostulacionNumeroRemate(estadoRegistro: number, numeroRemate : string): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.http.get(this.baseUrlApi + '/v1/revautos/remateActivosSinPostulacionNumeroRemate/'+estadoRegistro+'/'+numeroRemate,  { headers: this.getHttpHeaders() });
  }

   /**
   * Crear una nuevo auto
   * @param body
   */
  public guardarAutorizacion(body: AutorizacionDTO): Observable<any> {
    const url = this.baseUrlApi + '/v1/autorizaciones/guardarAutorizacion';

    return this.http.post(url, body, { headers: this.getHttpHeaders() });
  }
  /**
   * EndPoint que retorna los remates próximos a audiencia -> No funcionario
   * @param tipoUsuario Tipo de usuario logueado
   * @param idUsuario Identificador del usuario
   */
  findRematesProximoAudiencia(tipoUsuario: number, idUsuario: number): Observable<RemateCard[]> {
    return this.http.get<RemateCard[]>(
      this.baseUrlApi.concat('/v1/noFuncionario/rematesProximoAudiencia/')
        .concat(tipoUsuario.toString()).concat('/').concat(idUsuario.toString()),
      { headers: this.getHttpHeaders() }
    );
  }

  consultaRadicado(data): Observable<any> {
    return this.http.post(this.baseUrlApi + '/v1/common/getBlobStorageInvitadoPorNroRadicado',JSON.stringify(data), { headers: this.getHttpHeaders() });
  }

  public compruebaApoderadoVigente(idPostor : number): Observable<any>{
    const url = this.baseUrlApi + '/v1/autorizaciones/compruebaApoderadoVigente/'+idPostor;
    return this.http.get<any>(url, { headers: this.getHttpHeaders()});
  }

  public compruebaApoderadoGeneral(idPostor : number): Observable<any>{
    const url = this.baseUrlApi + '/v1/autorizaciones/compruebaApoderadoGeneral/'+idPostor;
    return this.http.get<any>(url, { headers: this.getHttpHeaders()});
  }

  /**
   * Manejador de errores de las peticiones HTTP
   * @param error error obtenido al realizar la petición
   */
  errorHandler(error: HttpErrorResponse) {
    //console.log("Se ha presentado un error: ", error);
    return throwError(error);
  }
  /**
   * EndPoint que retorna los remates a los cuales el usuario ha realizado una postulación -> No funcionario
   * @param body Información de filtrado y paginado
   */
  findRematesPostulaciones(body: FiltroBienesRemate): Observable<RemateDetail[]> {
    return this.http.post<RemateDetail[]>(
      this.baseUrlApi.concat('/v1/noFuncionario/rematesPostulaciones'), body,
      { headers: this.getHttpHeaders() }
    );
  }

  /**
   * EndPoint que retorna las próximas audiencias -> No funcionario
   * @param body Información de filtrado y paginado
   */
  findProximasAudiencias(body: FiltroBienesRemate): Observable<RemateDetail[]> {
    return this.http.post<RemateDetail[]>(
      this.baseUrlApi.concat('/v1/noFuncionario/findProximasAudiencias'), body,
      { headers: this.getHttpHeaders() }
    );
  }

  /**
   * EndPoint que retorna la cantidad de postulaciones realizadas por un Postor o Apoderado -> No funcionario
   * @param tipoUsuario Tipo de usuario logueado
   * @param idUsuario Identificador del usuario logueado
   */
  findCantidadPostulaciones(tipoUsuario: number, idUsuario: number): Observable<number> {
    return this.http.get<number>(
      this.baseUrlApi.concat('/v1/noFuncionario/cantidadPostulaciones/')
        .concat(tipoUsuario.toString()).concat("/").concat(idUsuario.toString()),
      { headers: this.getHttpHeaders() }
    );
  }

  /**
   * EndPoint que retorna el detalle de los remates
   * @param filtro Filtros de información
   */
  findRematesDetail(filtro: FiltroBienesRemate, ignorarToken: boolean = false): Observable<RemateDetail[]> {
    return this.http.post<RemateDetail[]>(
      this.baseUrlApi.concat('/v1/noFuncionario/rematesDetail'), filtro,
      { headers: this.getHttpHeaders(ignorarToken) }
    );
  }

  /**
   * EndPoint que retorna el detalle de los remates por los identificadores del auto
   * @param filtro Filtros de información
   */
  findRematesDetailByIdAutos(filtro: FiltroBienesRemate): Observable<RemateDetail[]> {
    return this.http.post<RemateDetail[]>(
      this.baseUrlApi.concat('/v1/noFuncionario/rematesDetailByIdAutos'), filtro,
      { headers: this.getHttpHeaders() }
    );
  }

  getRevActuacion(): Observable<any> {
    return this.http.get(this.baseUrlApi + '/v1/revautos/revActuacionPrevia', { headers: this.getHttpHeaders() });
  }

  /**
   * EndPoint que obtiene la autorización otorgada por el postor al apoderado
   * @param idApoderado Identificador del apoderado
   */
  public getAutorizacionApoderado(idApoderado: number): Observable<PermisoApoderadoDTO[]> {
    const url = this.baseUrlApi + '/v1/noFuncionario/permisoApoderado/'.concat(idApoderado.toString());
    return this.http.get<PermisoApoderadoDTO[]>(url, { headers: this.getHttpHeaders() });
  }

  /**
   * EndPoit que obtiene la autorización del apoderado para realizar postulaciones y participar en
   * la audiencia de uno o varios remates
   * @param idApoderado  Identificador del apoderado
   * @param idAuto Identificador del auto
   */
  public getAutorizacionPostulacionAudiencia(idApoderado: number, idAuto: number): Observable<PermisoApoderadoDTO> {
    const auto = (idAuto === null) ? 0 : idAuto;
    const url = this.baseUrlApi + '/v1/noFuncionario/autorizaPostulacionAudiencia/'
      .concat(idApoderado.toString()).concat("/").concat(auto.toString());
    return this.http.get<PermisoApoderadoDTO>(url, { headers: this.getHttpHeaders() });
  }

  /**
   * EndPoint que obtiene el consolidado de permisos otorgados al apoderado logueado.
   * Su funcionalidad es mostrar u ocultar las opciones de menú de acuerdo a las autorizaciones
   * que tiene el apoderado que ha ingresado al sistema
   * @param idApoderado Identificador del apoderado
   */
  public getAutorizaApoderadoLogin(idApoderado: number): Observable<AutorizaApoderadoDTO[]> {
    const url = this.baseUrlApi + '/v1/noFuncionario/autorizaApoderadoLogin/'.concat(idApoderado.toString());
    return this.http.get<AutorizaApoderadoDTO[]>(url, { headers: this.getHttpHeaders() });
  }

    /**
   * EndPoint que obtiene un objeto de tipo arrayList con el consolidado de autos
   * dependiendo de los filtros ingresados para la pantalla generear reporte de gestion para 
   * el rol Director Nacional
   * @body data request con los filtros
   */
  findFiltersReporteGestionDirectorNacional(data: any): Observable<any> {
    return this.http.post(this.baseUrlApi + '/v1/revautos/reporteGestionDirectorNacional', JSON.stringify(data),
      { headers: this.getHttpHeaders() });
  }

   /**
   * EndPoint que obtiene un objeto de tipo arrayList con el consolidado de autos
   * dependiendo de los filtros ingresados para la pantalla generar reporte ed correccion rol Director
   * y Director Nivel Central
   * @body data request con los filtros
   */
   findFiltersReporteCorreccion(data: any): Observable<any> {
    return this.http.post(this.baseUrlApi + '/v1/revautos/reporteCorreccionDirectorNacional', JSON.stringify(data),
      { headers: this.getHttpHeaders() });
  }

}
