import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { BlobStorageConsulta } from 'src/app/protected/comun/model/blob-storage-consulta';
import { DataSendService } from 'src/app/protected/services/data-send.service';
import { Columns } from 'src/app/remate/model/columns';
import { Ordenamiento, Paginate } from 'src/app/remate/model/paginate';
import { RemateService } from 'src/app/remate/services/remates/remates.service';
import { BaseUtil } from '../../model/base-util';
import { RemateDetail } from '../../model/remate-detail';
import { SharedService } from '../../services/shared.service';
import { MenuNoFuncionario, UtilService } from '../../services/util.service';

@Component({
  selector: 'app-audiencias-list',
  templateUrl: './audiencias-list.component.html',
  styleUrls: ['./audiencias-list.component.scss']
})
export class AudienciasListComponent extends BaseUtil implements OnInit {

  /** Tipo de usuario logueado */
  @Input() tipoUsuarioValue: number;
  /** Especifica si los datos son cargados desde el padre o desde este componente */
  @Input() fromParent: boolean;
  @Input() proximasAudiencias: RemateDetail[];
  /** Cantidad de registros */
  cantidadRegistros: number;
  /** Referencia al contenedor de la audiencia */
  @ViewChild('contenedorAudiencia') contenedorAudiencia: ElementRef;

  readonly name = 's-audiencias';

  responsiveOptions: any[] = [
    { breakpoint: '1024px', numVisible: 5 },
    { breakpoint: '768px', numVisible: 3 },
    { breakpoint: '560px', numVisible: 1 }
  ];

  // Propiedades del DetaView
  rows: number;
  columnas: Columns[];
  paginado: Paginate;
  pageNumber: number;
  first = 0;

  consulta: BlobStorageConsulta;

  constructor(protected translateService: TranslateService, protected lang: LanguageService,
              protected spinner: NgxSpinnerService, private remateService: RemateService,
              protected sharedService: SharedService, private dataSendService: DataSendService,
              protected utilService: UtilService, private router: Router) {
    super(translateService, lang, sharedService, utilService, spinner);
  }

  ngOnInit(): void {
    this.rows = 8; // Número de filas por página
    this.cantidadRegistros = 16; // Cantidad de registros que se desean obtener de la BD
    this.proximasAudiencias = [];
    this.pageNumber = 0; // Valor inicial del paginado
    this.initFilter(this.tipoUsuarioValue, false);
    this.loadProximasAudiencias();
  }

  /** Método que se encarga de listar las proximas audiencias */
  private loadProximasAudiencias() {
    if (!this.fromParent) {
      setTimeout(() => {
        this.spinner.show(this.name);
        this.searchFilter.paginado = this.getPaginate(this.pageNumber, this.cantidadRegistros, Ordenamiento.ASCENDENTE, 'audi.fechaAudiencia');
        this.remateService.findProximasAudiencias(this.searchFilter).subscribe(pa => {
          const results = [];
          if (pa != null && pa !== undefined) {
            // Mantis: 408
            pa.forEach(r => {
              if (!this.proximasAudiencias.find(p => p.idAuto === r.idAuto)) {
                results.push(r);
              }
            });
          }
          this.proximasAudiencias = this.proximasAudiencias.concat(results);
          //console.log('proximasAudiencia', this.proximasAudiencias);
          this.setImagenes();
        }, err => {
          console.error(err);
          this.spinner.hide(this.name);
        }, () => this.spinner.hide(this.name));
      }, 1000);
    }
  }

  /**
   * Método que se encarga de asignarle los separadores de miles al valor especificado
   * @param valor Valor del avalúo o valor base de la oferta
   */
  formatValueThousand(valor: any): string {
    return this.utilService.formatValueThousand(valor);
  }

  /**
   * Método que redirecciona al detalle
   * @param proximaAudiencia Información de la próxima audiencia
   */
  verDetalle(proximaAudiencia: RemateDetail) {
    this.dataSendService.setData({
      data: proximaAudiencia,
      componente: MenuNoFuncionario.REMATES_INICIO,
      redirectInicio: '/protected/rematesInicio'
    });
    this.router.navigate(['/protected/rematesDetalle']);
  }

  /**
   * Método que se encarga de capturar el cambio de página del DataView
   * @param evento Evento disparado cuando ocurre un cambio en el paginado
   */
  changePage(evento: any) {
    this.first = evento.first;
    const numeroRegistros = (this.proximasAudiencias.length - (this.first + this.rows));
    this.scrollToViewAudiencias();
    if (numeroRegistros === 0) {
      this.pageNumber += 1;
      this.loadProximasAudiencias();
    }
  }

  /** Regresa a la posición inicial del contenedor cuando se realiza el paginado */
  private scrollToViewAudiencias() {
    const element = this.contenedorAudiencia.nativeElement;
    element.scrollIntoView();
  }

  /** Método que se encarga de asignar las imágenes a las tarjetas de presentación del remate */
  private setImagenes() {
    this.consulta = { nroRadicado: 52631000042357, usuarioSistema: '' };
    for (const rem of this.proximasAudiencias) {
      rem.consolidadoImagenes = [];
      for (const bien of rem.bienes) {
        for (const img of bien.imagenes) {
          this.consulta.nroRadicado = img.metadata.numeroRadicado;
          this.consulta.usuarioSistema = this.dataSendService.getValueItem('usuarioSistema');
          this.remateService.consultaRadicado(this.consulta).subscribe(pic => {
            img.file = 'data:image/jpeg;base64,' + pic.body;
            rem.consolidadoImagenes.push(img.file);
          }, err => console.error(err));
        }
      }
    }
  }

}
