import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ParametroService } from 'src/app/protected/services/parametros/parametroService';

@Component({
  selector: 'app-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.scss']
})
export class RecaptchaComponent implements OnInit {

  @ViewChild('recaptcha') recaptchaElement: ElementRef<any>;
  @Output() recaptchaEvent = new EventEmitter<boolean>();
  recaptchaValue: string = '';

  constructor(private parametroService: ParametroService) { }

  ngOnInit(): void {
    this.getCaptchaValue();
  }

  public loadCaptcha() {
    this.getCaptchaValue();
  }

  renderReCaptch() {
    window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
      'sitekey': this.recaptchaValue,
      'callback': (response) => {
        this.recaptchaEvent.emit(true);
      }
    });
  }

  private getCaptchaValue(): void {
    this.parametroService.findParametro('CAPTCHA_SITEKEY').subscribe(captcha => {
      if (captcha !== null) {
        this.recaptchaValue = captcha.camposParametrosAdpt[0].valor;
        this.addRecaptchaScript();
      }
    }, err => console.error(err));
  }

  addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }
    (function (d, s, id, obj) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

}
