<p-table [columns]="columnas" [value]="datos" [responsive]="true" tableStyleClass="dynamic-table"
  (onPage)="changePage($event)" [paginator]="paginator" [rows]="rows" [(first)]="first" [totalRecords]="totalRecords"
  (onLazyLoad)="onLazyLoadEvent($event)" [lazy]="lazy" [resizableColumns]="true" [loading]="loading">
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [ngStyle]="{'display': col.hidden === true ? 'none' : ''}">
        <span>{{ col.header | translate }}</span>
      </th>
      <th *ngIf="viewActions" [ngClass]="styleActions">
        <span>{{ nombreCabeceraActions | translate }}</span>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" [ngSwitch]="col.type" [ngStyle]="{'display': col.hidden === true ? 'none' : ''}">
        <span class="ui-column-title">{{col.header | translate}}</span>
        <span *ngSwitchCase="'number'">${{ getDataPreview(rowData, col.field) | number}}</span>
        <span *ngSwitchCase="'date'">{{ getDataPreview(rowData, col.field) | date:formatDate }}</span>
        <span *ngSwitchCase="'hour'">{{ getDataPreview(rowData, col.field) | date:"HH:mm" }}</span>
        <span *ngSwitchCase="'semaforo'" [ngClass]="paintSemaforo(getDataPreview(rowData, col.field))">{{traducirEstadoValidacion(rowData, col.field) }}</span>
        <span *ngSwitchCase="'link'" [ngClass]="enableLinkCargarInformacion(rowData) ? 'type-link-style' : 'unlink-style'" (click)="cargarInformacion(rowData)">{{ getDataPreview(rowData, col.field) | translate }}</span>
        <span *ngSwitchCase="'estadoAudiencia'" [ngClass]="paintSemaforo(getDataPreview(rowData, col.field))">{{ traducirEstadoAudiencia(rowData, col.field) }}</span>
        <span *ngSwitchCase="undefined">{{ getDataPreview(rowData, col.field) | translate}}</span>
      </td>
      <td *ngIf="viewActions">
        <span class="ui-column-title">{{ nombreCabeceraActions | translate }}</span>
        <span *ngFor="let x of rowData['actions']">
          <span *ngIf="!x.hidden" [ngClass]="(!x.isActive) ? 'disable-icon-svg' : ''"
            class="icono-action" (click)="sendActionHandler(x, rowData)">
            <i [ngClass]="(!x.isActive) ? 'icon-cursor-svg-disabled' : ''" class="{{ x?.icon }} icono-svg"
              title="{{ x?.iconTitle | translate}}"></i>
          </span>
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>