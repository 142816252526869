import { DataShare } from "../../services/data-send.service";

/** Clase que administra los elementos del localStorage */
export class LocalStorage {

  /**
   * Obtiene el valor del `LocalStorage`
   * @param key Palabra clave de búsqueda
   */
  get(key: string): DataShare {
    let data: DataShare = null;
    try {
      data = JSON.parse(localStorage.getItem(key));
    } catch (error) {
      console.error(error);
    }
    return data;
  }

  /**
   * Almacena los datos en el `LocalStorage`
   * @param key Palabra clave de búsqueda
   * @param data Información que se va a almacenar
   */
  set(key: string, data: DataShare) {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Elimina el valor del `LocalStorage`
   * @param key Palabra clave de búsqueda
   */
  remove(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Elimina todos los registros que contentan las `keys`
   * @param keys Palabras clave de búsqueda
   */
  removeAll(keys: string[]) {
    try {
      keys.forEach(key => {
        localStorage.removeItem(key);
      });
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Obtiene el valor del `LocalStorage` sobre cualquier modelo almacenado
   * @param key Palabra clave de búsqueda
   */
  getValueItem(key: string): any {
    let data: any = null;
    try {
      data = localStorage.getItem(key);
    } catch (error) {
      console.error(error);
    }
    return data;
  }

}
