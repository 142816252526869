import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {BaseService} from '../../../core/services/base.service';
import {Usuario} from "../../comun/model/usuario";

@Injectable({
  providedIn: 'root'
})
export class UsuarioExtService extends BaseService {

  controlExcepcion( error: HttpErrorResponse ) {
    return throwError(error);
  }

  /**
   * return
   * UsuarioExt.ts
   */
  public getUsuarioExt(): Observable<any> {
    const url = this.baseUrlApi + '/v1/common/usuario/ext/getUsuarioExt';
    return this.http.post(url, null, {headers: this.getHttpHeaders()});
  }

  public getNitData(nit:string): Observable<any>{
    const url = this.baseUrlApi + '/v1/common/usuario/nit/consultarUsuarioRutPorNit';
    const body = {nit: nit};
    return this.http.post(url, body, { headers: this.getHttpHeaders() });
  }

  /**
   * Obtener el usuario externo por el identificador
   * @param id Identificador del usuario externo
   */
  public getUsuarioExtById(id: number): Observable<any> {
    const url = this.baseUrlApi + '/v1/common/usuario/ext/usuarioExtById/'.concat(id.toString());
    return this.http.get(url, {headers: this.getHttpHeaders()});
  }

  /**
   * return
   * UsuarioExt.ts
   */
  public getUsuario(): Observable<Usuario> {
    const url = this.baseUrlApi + '/v1/common/usuario/ext/getUsuario';
    return this.http.post<Usuario>(url, null, {headers: this.getHttpHeaders()});
  }

}
