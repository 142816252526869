import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {BaseService} from '../../../core/services/base.service';


@Injectable({
  providedIn: 'root'
})
export class ParametroService extends BaseService {

  controlExcepcion( error: HttpErrorResponse ) {
    return throwError(error);
  }

  public findParametro(nombreParametro: string): Observable<any> {
    const url = this.baseUrlApi + '/v1/common/parametros/findParametroByNombre/'.concat(nombreParametro);
    return this.http.get(url);
  }


}
