import { LocalStorage } from './../secretarioAdHoc/model/local-storage';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GeneraActa } from 'src/app/core/model/genera-acta';
import { ParticipanteAudienciaDTO } from '../funcionario/model/participante-audiencia-dto';

/**
 * Servicio que distribuye datos entre componentes no relacionados
 * @since 1.0.0.
 */
@Injectable({
  providedIn: 'root'
})
export class DataSendService extends LocalStorage {

  private data$: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor() { super(); }

  /** Método que obtiene el Observable del `BehaviorSubject` */
  getData(): Observable<any> {
    return this.data$.asObservable();
  }

  /**
   * Método que asigna el nuevo valor al `BehaviorSubject`
   * @param data Información a enviar
   */
  setData(data: DataShare): void {
    this.data$.next(data);
  }

  /** Método que obtiene el valor del `BehaviorSubject` */
  getValue(): DataShare {
    return this.data$.getValue();
  }

}

/** Interfaz que gestiona los datos que son pasados desde un componente a otro */
export interface DataShare {
  data?: any;
  componente?: number;
  redirectInicio?: string;
  filter?: any;
  idPostulacion?: number;
  numeroRemate?: string;
  redirectButton?: string;
  generaActa?: GeneraActa;
  mejoresPostores?: ParticipanteAudienciaDTO[];
  nroRadicado?: number;
  dataSubsana?: any;
  extraData?: any;//datos extra que no estan dentro del estandar definido para el atributo data. Implementacion especial para dar solucion a mantis: 649 y 698
}

