import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-error',
  templateUrl: './modal-error.component.html',
  styleUrls: ['./modal-error.component.scss']
})
export class ModalErrorComponent implements OnInit {

  /** Muestra u oculta la modal */
  viewModalError: boolean;
  /** Mensaje principal de la modal */
  mensaje: string;
  /** Icono de la modal */
  icono: string;
  /** Título de la modal */
  titulo: string;
  /** Información adicional para la modal */
  data: string;
  /** Enlace de redirección entre componentes */
  redirect: string;
  /** Determina si el componente tendra el icono (X) de cerrar   */
  public closable: boolean = false

  /** Atributo que envía data personalizada cuando el modal es cerrado */
  @Output() sendData: EventEmitter<string> = new EventEmitter<string>();

  constructor(private translate: TranslateService, private lang: LanguageService,
    private router: Router) { }

  ngOnInit(): void {
    this.translate.setDefaultLang(this.lang.browserLanguage());
    this.icono = (this.icono === undefined) ? 'assets/images/e-peligro.svg' : this.icono;
    this.mensaje = (this.mensaje === undefined) ? 'Aqui va el mensaje de error' : this.mensaje;
    this.titulo = (this.titulo === undefined) ? 'Aqui va el titulo del error' : this.titulo;
    this.viewModalError = (this.viewModalError === undefined) ? false : this.viewModalError;
    this.data = (this.data === undefined) ? 'close-modal' : this.data;
    this.closable = (this.closable === undefined || this.closable === null) ? false : this.closable;
  }

  /** Método que se encarga de cerrar la modal */
  closeModal() {
    this.viewModalError = false;
    if (this.redirect) {
      this.router.navigate([this.redirect]);
      this.redirect = undefined;
      return;
    }
    this.sendData.emit(this.data);
  }

  /**
   * Evento de captura de cierre de modal, clic icono X
   */
  onHide(event) {
    if (this.viewModalError === true) {
      this.closeModal();
    }
  }

}
