<div class="col-12 px-0">
  <header class="he_header container-fluid">
    <div class="row">
      <div class="col-lg-2 col-sm-2 col-4 py-2 he_logo d-flex justify-content-end align-items-center">
        <!--Logo header-->
        <a href="https://www.gov.co" class="my-0">
          <img [src]="fileImagenHeader" alt="img-header" />
        </a>
      </div>
      <div class="align-items-center col-2 col-lg-7 col-sm-7 d-flex he_menu">
        <!-- <p class="d-sm-none d-block">¿Sabes que es GOV.CO? Conócelo aquí</p> -->
      </div>
      <div class="align-items-center col-6 col-sm-3 d-flex justify-content-md-start justify-content-end he_menu ">
  
        <a href="javascript:void(0);" (click)="compruabeLenguage()" class="option-ayuda">
          {{ 'authentication.manual' | translate }}
        </a>

        <div class="select__lang">
          <select #langSelect (change)="changeLanguage(langSelect.value)">
            <option *ngFor="let lang of languages" [value]="lang.value"
              [selected]="lang.value === translate.currentLang">
              {{lang.label}}
            </option>
          </select>
        </div>

      </div>
    </div>
  </header>
  <app-modal-success #modalSuccess></app-modal-success>
    <!-- Modal de error -->
    <app-modal-error>
    </app-modal-error>
</div>
