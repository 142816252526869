import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = localStorage.getItem('token');
    const idExterno = localStorage.getItem('idExterno');
    const routeExtras = this.router.getCurrentNavigation().extras;
    const fromRegistrarInvitado = routeExtras?.state?.fromRegistrarInvitado;

    if (token && idExterno) {
      return true;
    }

    //Mantis incidencia: 0000883
    if(fromRegistrarInvitado === true){
      return true;
    }

    this.router.navigate(['/home']);

    return false;
  }
}
