/**
 * ROLES
 * @since 1.0.0.
 */
export enum RoleEnum {

  FUNCIONARIO = 23,
  SECRETARIO = 24,
  DIRECTOR = 25,
  POSTOR = 26,
  APODERADO = 27,
  DEUDOR = 28

}
