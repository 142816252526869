<div class="row">
  <div class="col-sm-12 col-md-6 col-lg-3 p-2" *ngFor="let postulacion of postulaciones">
    <div class="card">
      <div class="descuento">{{ postulacion.porcentajeLicitacion }}%</div>

      <p-galleria [(value)]="postulacion.consolidadoImagenes"
        [responsiveOptions]="responsiveOptions"
        [containerStyle]="{'max-width': '100%'}"
        [numVisible]="5"
        class="ng-galleria"
        [circular]="true"
        [showItemNavigators]="postulacion.consolidadoImagenes.length > 1"
        [showThumbnails]="false"
        [showIndicators]="postulacion.consolidadoImagenes.length > 1"
        [showItemNavigatorsOnHover]="true"
        [transitionInterval]="1000"
        [showIndicatorsOnItem]="true">
        <ng-template pTemplate="item" let-item>
            <img [src]="item" style="width: 100%; display: block; object-fit: cover; height: 250px;" />
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="p-grid p-nogutter p-justify-center">
                <img [src]="item" style="display: block;" />
            </div>
        </ng-template>
      </p-galleria>
      <div class="alert-primary m-2 p-1 border-0">
        {{ "postulacionesList.numeroRemate" | translate }}:
        <strong>{{ postulacion.numeroRemate }}</strong>
      </div>

      <div class="m-2 infocards">
        <h3>{{ postulacion.municipio }} - {{ postulacion.departamento }}</h3>
        {{ postulacion.descripcionBien }}
      </div>

      <div class="row m-2">
        <div class="col-6 indicadores m-0 p-0">
          <div>{{ "postulacionesList.fechaAudiencia" | translate }}</div>
          <div>{{ postulacion.fechaAudiencia | date:"dd-MM-yyyy" }}</div>
        </div>
        <div class="col-6 indicadores m-0 p-0">
          <div>{{ "postulacionesList.horaAudiencia" | translate }}</div>
          <div>{{ postulacion.horaAudiencia | date:"HH:mm" }}</div>
        </div>
      </div>

      <div class="indicadores m-2">
        <div>
          {{ "postulacionesList.avaluoBien" | translate }}:
          <strong>${{ formatValueThousand(postulacion.avaluoBien) }}</strong>
        </div>
      </div>

      <div class="oferta m-2">
        <div>{{ "postulacionesList.valorBase" | translate }}</div>
        <div>${{ formatValueThousand(postulacion.valorBaseOferta) }}</div>
      </div>

      <div class="indicadores m-2" *ngIf="postulacion.nombrePostor && esRolApoderado()">
        <div>
          {{ "postulacionesList.representante" | translate }}:
          <strong>{{ postulacion.nombrePostor }}</strong>
        </div>
      </div>

      <div class="m-2">
        <button class="btn w-100" (click)="verDetalle(postulacion)">
          {{ "postulacionesList.btnVer" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Spinner -->
<app-spinner [name]="name"
  [color]="'#323232'" [bdColor]="'rgba(255,255,255,0)'">
</app-spinner>
