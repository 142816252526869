import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {BaseService} from '../base.service';
import {UsuarioSistema} from "../../../model/usuario.sistema";
import {UsuarioSistemaService} from "../../../service/common/usuario.sistema.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  /*login(username: string, contrasena: string): Observable<any> {
    const usuario = {email: username, password: contrasena};

    return this.http.post<any>(`${environment.loginService}/api/login`, usuario).pipe(map(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem('token', JSON.stringify(user));
      return user;
    }));
  }*/

  constructor(private usuarioSistemaService: UsuarioSistemaService,
              protected http: HttpClient) {
    super(http);
  }

  login(username: string, contrasena: string): Observable<any> {
    const user = {usernameOrEmail: username, password: contrasena};

    const url = this.baseUrlAuth + '/signin';
    return this.http.post(url, user);
  }


  logout() {
    // this.logoutUser();
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    this.initializer().then(r => r);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  controlExcepcion(error: HttpErrorResponse) {
    return throwError(error);
  }

  public loginJWT(): Observable<any> {
    const user = {
      usernameOrEmail: 'EnEtldJjmen8BQk8XlbS6Ua5f7W3MhQ5lMmOEnIWyxM=',
      password: 'JWG4oaaul1oX+fxYdUDO/r7HPcxjwMPnRDHfgf2oy5I='
    };
    const url = this.baseUrlAuth + '/signin';
    return this.http.post(url, user);
  }

  public getUrlLogin(): Observable<any> {
    const url = this.baseUrlAuth + '/urlLogin';
    const tipoUser = localStorage.getItem('tipoUsuario');
    if (tipoUser === null) {
      throw new Error('Error tipoUsuario NULL');
    }
    const urlLogin = {
      tipoUsuario: tipoUser
    };
    return this.http.post(url, urlLogin);
  }

  public loginUser(request): Observable<any> {
    const url = this.baseUrlAuth + '/login';
    return this.http.post(url, request);
  }

  public logoutUser(): Observable<any> {
    const url = this.baseUrlAuth + '/logout';
    return this.http.post(url, 'null');
  }

  /**
   * inicializador
   */
  async initializer() {
    try {
      await this.init();
      await this.loadUsuarioSistema();
    } catch (error) {
      console.error('Error message', error);
    }
  }

  /**
   * token HOME
   */
  private async init() {
    let res = {
      accessToken: ''
    };
    this.loginJWT().subscribe(
      (result: any) => {
        res = result;
        if (res === null) {
          throw new Error('Error res NULL');
        }
        if (res.accessToken === null) {
          throw new Error('Error accessToken NULL');
        }
        localStorage.setItem('token', res.accessToken);
        // this.loadUsuarioSistema();
      }, error => {
        console.error(error);
      });
  }

  /**
   * cargamos usuario sistema
   */
  private async loadUsuarioSistema() {
    this.usuarioSistemaService.getUsuario().subscribe(
      data => {

        if (!data) {
          throw new Error('Usuario sistema no encontrado');
        }

        const usuarioSistema: UsuarioSistema = data;

        if (usuarioSistema.error != null) {
          throw new Error(usuarioSistema.error);
        }

        if (usuarioSistema.token == null) {
          throw new Error('Usuario sistema no encontrado');
        }

        // cargamos el usuario sistema
        localStorage.setItem('usuarioSistema', usuarioSistema.token);

      }, error => {
        console.error(error);
      });

  }

}
