<div *ngIf="timeOnly === false"
  [ngClass]="{'invalid': !customCalendar.valid && (customCalendar.touched || customCalendar.dirty)}">
  <label *ngIf="label" [class]="labelClass">{{label | translate}}: <span style="color: red"
      *ngIf="required === true">*</span></label>
  <p-calendar #calendarNg #customCalendar="ngModel" [(ngModel)]="dayCalendar" (onSelect)="selectDate($event)"
    [dateFormat]="dateFormat" [minDate]="minDateValue" [maxDate]="maxDateValue" [readonlyInput]="true" [styleClass]="'background-calendar'"
    [locale]="locale" [monthNavigator]="monthNavigator" [yearNavigator]="yearNavigator" [showButtonBar]="showButtonBar"
    [yearRange]="yearRange" [placeholder]="placeHolder" [showIcon]="true"
    [ngClass]="{ 'j-error-calendar': errorStyleCalendar || (!customCalendar.valid && (customCalendar.touched || customCalendar.dirty))}"
    class="input-calendar-custom" [disabled]="disabled" [required]="required" [ngModelOptions]="{ standalone: true }"
    [timeOnly]="timeOnly">
  </p-calendar>
  <div class="form-error" aria-live="assertive">
    <div *ngIf="!customCalendar.valid && (customCalendar.touched || customCalendar.dirty)">
      <span *ngIf="customCalendar.errors.required" style="color: red; font-size: 0.8em;">{{messageRequired |
        translate}}</span>
    </div>
  </div>
</div>
<div *ngIf="timeOnly === true"
  [ngClass]="{'invalid': !customCalendar.valid && (customCalendar.touched || customCalendar.dirty)}">
  <label *ngIf="label" [class]="labelClass">{{label | translate}}: <span style="color: red"
      *ngIf="required === true">*</span></label>
  <p-calendar #calendarNg #customCalendar="ngModel" [(ngModel)]="dayCalendar" (onSelect)="selectDate($event)"
    [readonlyInput]="true" [styleClass]="'background-calendar'" style="height: 100% !important;"
    [ngClass]="{ 'j-error-calendar': errorStyleCalendar || (!customCalendar.valid && (customCalendar.touched || customCalendar.dirty))}"
    class="input-calendar-hour-custom" [required]="required" [ngModelOptions]="{ standalone: true }"
    [timeOnly]="true">
  </p-calendar>
  <div class="form-error" aria-live="assertive">
    <div *ngIf="!customCalendar.valid && (customCalendar.touched || customCalendar.dirty)">
      <span *ngIf="customCalendar.errors.required" style="color: red; font-size: 0.8em;">{{messageRequired |
        translate}}</span>
    </div>
  </div>
</div>