import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {BaseService} from 'src/app/core/services/base.service';


@Injectable({
  providedIn: 'root'
})
export class FileService extends BaseService {

  getDocumentsByIdAuto(idAuto: number): Observable<any> {
    const url = this.baseUrlApi + '/v1/common/documents/findDocumentsByIdAuto';
    return this.http.post(url, idAuto, { headers: this.getHttpHeaders() });
  }

  getDocumentsByIdAutorizacion(idAutorizacion: number): Observable<any> {
    const url = this.baseUrlApi + '/v1/common/documents/findDocumentsByIdAutoriz';
    return this.http.post(url, idAutorizacion, { headers: this.getHttpHeaders() });
  }

  getImagesByIdBien(idBien: number): Observable<any> {
    const url = this.baseUrlApi + '/v1/common/images/findImagesByIdBien/';
    return this.http.post(url, idBien, { headers: this.getHttpHeaders() });
  }


}
