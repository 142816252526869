import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.scss']
})
export class UserManualComponent {

  constructor(private elRef: ElementRef) {
    elRef.nativeElement.ownerDocument.body.style.overflow = 'hidden';
  }

  @ViewChild('modalView', {static: true}) modalView$ : ElementRef;

  openModal() {
    this.modalView$.nativeElement.classList.add('visible');
  }
}
