export class Paginate {

  pageSize: number;
  pageNumber: number;
  order?: Ordenamiento;
  columnOrderName?: string;

}

export enum Ordenamiento {
  ASCENDENTE = 'ASC',
  DESCENDENTE = 'DESC'
}
