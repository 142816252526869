import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng';
import { FileDTO } from 'src/app/remate/model/file-dto';
import { FilesLoad } from 'src/app/remate/model/files-load';
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/language/language.service';
import { truncate } from 'fs';

@Component({
  selector: 'app-file-upload-anexo',
  templateUrl: './file-upload-anexo.component.html',
  styleUrls: ['./file-upload-anexo.component.scss']
})
export class FileUploadAnexoComponent implements OnInit {

  uploadedFiles: any[] = [];
  /**
   * Atributo que define el nombre del control `FileUpload`.
   * Para el uso del Translate se debe asignar obligatoriamente un valor a esta propiedad
   * en el componente padre.
   */
  @Input() name: string;
  /** Atributo que define el tipo de archivos permitidos para el cargue */
  @Input() accept: string;
  /** Atributo que define el tamaño máximo permitido para cargar un archivo */
  @Input() maxFileSize: number;
  /** Atributo que define si el control acepta más de un archivo */
  @Input() multiple: boolean;
  /** Atributo que define el nombre del botón de selección para el control */
  @Input() chooseLabel: string;
  /** Mensaje personalizado de error cuando el tamaño del archivo es superior al definido */
  @Input() invalidFileSizeMessageDetail: string;
  /** Define la longitud máxima de caracteres permitidos para el nombre del archivo  */
  @Input() sizeFileName: number;
  /** Referencia al componente `ModalErrorComponent` */
  @ViewChild('modalMsgAlert') modalMsgAlert: ModalErrorComponent;
  /** Contenedor de los archivos cargados */
  myFiles: File[];

  /** Referencia al componente PrimeNg `FileUpload` */
  @ViewChild('fileUpload') fileUpload: FileUpload;

  public invalidFileTypeMessageSummary: string;
  public invalidFileTypeMessageDetail: string;

  @Input() fechaDocumento: string;
  @Input() totalFolios: number;
  @Input() showDetailInfo: boolean;

  //Determina si se debe mostrar o no el boton de cargar archivo
  @Input() public showChooseButton: boolean;

  //Determina si se puede modificar informacion
  @Input() public editable: boolean;

  /** Atrubuito notifica al componente invocador la eliminacion de un archivo */
  @Output() eventDeleteFile: EventEmitter<any> = new EventEmitter<any>();

  //Determina si se requiere cargar almenos un archivo
  @Input() public required: boolean;

  public invalidFileSize: boolean = false;
  public invalidFileType: boolean = false;
  public invalidFileEmpty: boolean = false;

  constructor(private translate: TranslateService, private lang: LanguageService) {
    this.translate.setDefaultLang(this.lang.browserLanguage());
  }

  ngOnInit(): void {
    this.translate.setDefaultLang(this.lang.browserLanguage());
    this.init();
  }

  init() {
    try {
      this.name = (this.name === undefined || this.name === null) ? 'files[]' : this.name;
      this.accept = (this.accept === undefined || this.accept === null) ? '' : this.accept;
      this.maxFileSize = (this.maxFileSize === undefined || this.maxFileSize === null) ? 4194828 : this.maxFileSize;
      this.multiple = (this.multiple === undefined || this.multiple === null) ? false : this.multiple;
      this.sizeFileName = (this.sizeFileName === undefined || this.sizeFileName === null) ? 60 : this.sizeFileName;
      this.chooseLabel = (this.chooseLabel === undefined || this.chooseLabel === null) ? this.translate.instant('cargadorArchivos.btnCargarArchivo') : this.chooseLabel;
      this.invalidFileSizeMessageDetail = (this.invalidFileSizeMessageDetail === undefined || this.invalidFileSizeMessageDetail === null) ? this.translate.instant('cargadorArchivos.invalidFileSizeMessageDetail') : this.invalidFileSizeMessageDetail;
      this.invalidFileTypeMessageSummary = this.translate.instant('cargadorArchivos.invalidFileTypeMessageSummary');
      this.invalidFileTypeMessageDetail = this.translate.instant('cargadorArchivos.invalidFileTypeMessageDetail');
      if (!this.multiple) {
        this.name = 'file';
      }
      this.fechaDocumento = (this.fechaDocumento === undefined || this.fechaDocumento === null) ? '' : this.fechaDocumento;
      this.totalFolios = (this.totalFolios === undefined || this.totalFolios === null) ? 0 : this.totalFolios;
      this.showDetailInfo = (this.showDetailInfo === undefined || this.showDetailInfo === null) ? true : false;
      this.showChooseButton = (this.showChooseButton === undefined || this.showChooseButton === null) ? true : this.showChooseButton;
      this.editable = (this.editable === undefined || this.editable === null) ? true : this.editable;
      this.required = (this.required === undefined || this.required === null) ? false : this.required;
    } catch (error) {
      console.error('Error message', error);
    }
  }

  /**
   * Método que carga los archivos
   * @param event Evento de cargue
   */
  public uploadFilesAnexos(event: any) {
    this.validatedLongitudCaracteres(event.files);
    if (!this.modalMsgAlert.viewModalError) {
      this.myFiles = event.files;
    }
  }

  /**
   * Valida que el nombre del archivo no supere los 60 caracteres.
   * Si el atributo `sizeFileName` es definido, se toma en cuenta su valor
   * para realizar la validación.
   * @param files Total de archivos cargados
   */
  private validatedLongitudCaracteres(files: File[]) {
    for (const x of files) {
      const nombreArchivo = x.name.substring(0, x.name.lastIndexOf('.'));
      if (nombreArchivo.length > this.sizeFileName) {
        this.modalMsgAlert.titulo = 'modalError.titleErrorFileSize';
        this.modalMsgAlert.mensaje = String(this.translate.instant('modalError.msgErrorFileSize'))
          .replace('{0}', this.sizeFileName.toString());
        this.modalMsgAlert.viewModalError = true;
        this.fileUpload.clear();
        break;
      }
    }
  }

  /**
   * Elimina archivos y posibles tipos de errores
   */
  public limpiarArchivo() {
    this.myFiles = [];
    this.fileUpload.clear();
    this.invalidFileEmpty = false;
    this.invalidFileSize = false;
    this.invalidFileType = false;
  }

  /** Obtener los archivos cargados del `FileUpload` */
  public async getFilesAnexos(): Promise<FilesLoad[]> {
    const documentos: FilesLoad[] = [];
    if (this.myFiles === undefined) { return []; }
    if (this.myFiles.length > 0) {
      for (let index = 0; index < this.myFiles.length; index++) {
        const base64File = await this.converterFileBase64(this.myFiles[index]);
        const metadata = this.myFiles[index] as any;
        const fileDTO = new FileDTO(
          metadata.size,
          metadata.type,
          metadata.lastModified,
          metadata.name,
          metadata?.numeroRadicado,
          metadata?.numeroFolios,
          metadata?.fechaDocumento
        );
        documentos.push(new FilesLoad(base64File, fileDTO));
      }
    }
    return documentos;
  }

  /**
   * Método que se encarga de convertir el archivo en Base64
   * @param file Archivo que va a ser convertido a Base64
   */
  private converterFileBase64(file: any) {
    return new Promise<any>(resolve => {
      const fr = new FileReader();
      if (file?.file) {
        var blob = undefined;
        if (file?.type && file?.type === 'pdf') {
          if (!file?.file.startsWith('data:application/pdf;base64,')) {
            blob = new Blob(['data:application/pdf;base64,' + file?.file], { type: 'text/plain' });
          } else {
            blob = new Blob([file?.file], { type: 'text/plain' });
          }
        } else {
          blob = new Blob([file?.file], { type: 'text/plain' });
        }
        fr.readAsText(blob);
      } else {
        if(file instanceof Blob){
          fr.readAsDataURL(file);
        }else{
          console.error('Error lectura contenido archivo. No es de tipo Blob');
        }
      }
      fr.onload = () => {
        resolve(fr.result);
      };
    });
  }

  /**
   * Elimina el archivo seleccionado
   * @param file 
   * @param index 
   */
  public deleteFile(file, index) {
    if (file) {
      this.myFiles.splice(index, 1);
      if (this.myFiles && this.myFiles.length <= 0) {
        this.showChooseButton = this.editable === true ? true : false;
        this.invalidFileSize = false;
        this.invalidFileEmpty = false;
        if (this.required === true) {
          this.invalidFileEmpty = true;
        }
      }
      this.eventDeleteFile.emit(file);
    }
  }

  /**
   * Determina si el componente no tiene archivos cargados
   * @returns 
   */
  public isEmpty(setError: boolean = true) {
    this.invalidFileSize = false;
    this.invalidFileEmpty = false;
    if (!this.myFiles || (this.myFiles && this.myFiles.length <= 0)) {
      if (this.required === true && setError === true) {
        this.invalidFileEmpty = true;
      }
      return true;
    } else {
      return false;
    }
  }

  /**
   * Setea el array de tipo FilesLoad al tipo propio de File
   * @param files 
   */
  public setFiles(files: any[]) {
    this.myFiles = [];
    this.invalidFileEmpty = false;
    this.invalidFileSize = false;
    if (files && files.length > 0) {
      var data = files.map(f => {
        f.name = f.metadata.name;
        f.lastModified = f.metadata.lastModified;
        f.size = f.metadata.size;
        f.type = f.metadata.type;
        f.numeroRadicado = f.metadata.numeroRadicado;
        f.numeroFolios = f.metadata.numeroFolios;
        f.fechaDocumento = f.metadata.fechaDocumento;
        return f;
      });
      this.myFiles = data as any;
    }
  }

  /**
   * Method to allow to download file
   * @param base64File
   * @param name
   * @param type
   */
  public onClickDownloadFile(base64File: any, name: any = 'file', type: any) {
    if (base64File) {
      if (type === 'pdf') {
        this.downloadPdf(base64File, name, type);
      }
    }
  }

  /**
  * method to allow to download file
  * @param base64String
  * @param fileName
  * @param type
  */
  downloadPdf(base64String, fileName, type) {
    const source = `data:application/${type};base64,${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();
  }

  /**
   * Evento de seleccion de archivo
   * @param event 
   */
  public onSelect(event) {
    if (event && event?.files) {
      this.invalidFileEmpty = false;
      this.invalidFileSize = false;     
      if (this.multiple === true) {
        for (let i = 0; i < event?.files?.length; i++) {
          if (event.files[i].size > this.maxFileSize) {
            this.invalidFileSize = true;
            this.invalidFileEmpty = false;
          }
        }
      } else {
        let file = event.files[0];
        if (file.size > this.maxFileSize) {
          this.invalidFileSize = true;
          this.invalidFileEmpty = false;
        }
      }
      if (event?.files.length > 0 && this.invalidFileSize === false) {
        this.invalidFileEmpty = false;
      }
    }
  }

  /**
   * Construye la informacion del mensaje de tamaño invalido del archivo
   * @returns 
   */
  public buildInvalidFileSizeMessage() {
    if (this.invalidFileSize === true) {
      this.invalidFileEmpty = false;
      let msg = this.translate.instant('cargadorArchivos.invalidFileSizeMessage');
      let size = this.formatBytes(this.maxFileSize, 3);
      return this.replaceVariables(msg, [size]);
    } else {
      return "";
    }
  }

  /**
  * Construye la informacion del mensaje de archivo obligatorio
  * @returns 
  */
  public buildInvalidFileEmptyMessage() {
    if (this.invalidFileEmpty === true && this.required === true) {
      this.invalidFileSize = false;
      let msg = this.translate.instant('cargadorArchivos.invalidFileEmptyMessage');
      let extension = this.extensionAccept();
      let size = this.formatBytes(this.maxFileSize, 3);
      return this.replaceVariables(msg, [extension, size]);
    } else {
      return "";
    }
  }

  /**
   * Obtener informacion del peso del archivo a su conversion mas cercana
   * @param bytes 
   * @param decimals 
   * @returns 
   */
  private formatBytes(bytes, decimals) {
    if (bytes == 0) {
      return "0 Byte";
    }
    var k = 1024; //Or 1 kilo = 1000
    var sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i];
  }

  /**
   * Permite saber en lenguaje natural la extension de los tipos de archivos que se aceptan
   * @returns 
   */
  private extensionAccept() {
    if (this.accept) {
      if (this.accept === 'application/pdf') {
        return 'PDF';
      } else {
        return '*';
      }
    } else {
      return '*';
    }
  }

  /**
  * Realiza replace de las variables enviadas como parametro en el texto
  * @param texto
  * @param variables
  * @returns
  */
  private replaceVariables(texto: string, variables: any[]) {
    return variables.reduce((f, s, i) => `${f}`.replace(`{${i}}`, s), texto)
  }

}
