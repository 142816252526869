/**
 * Clase que administra la metadata del archivo
 */
export class FileDTO {

  /**
   * Constructor de la clase `FileDTO`
   * @param size Tamaño del archivo
   * @param type Tipo de archivo
   * @param lastModified Tiempo de modificación
   * @param name Nombre del archivo
   * @param numeroRadicado Número de radicado del archivo cargado
   * @param numeroFolios Número de folios
   */
  constructor(
    public size?: number,
    public type?: string,
    public lastModified?: number,
    public name?: string,
    public numeroRadicado?: number,
    public numeroFolios?: number,
    public fechaDocumento?: number
  ) {}

}
