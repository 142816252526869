<div>
  <p-fileUpload #fileUpload 
    [ngClass]="{'file-upload-anexo' : showChooseButton === true, 'file-upload-anexo-hidden-choose-button' : showChooseButton === false}"
    [name]="name" [accept]="accept" [maxFileSize]="maxFileSize"
    [showUploadButton]="false" [showCancelButton]="false" [multiple]="multiple" 
    [invalidFileSizeMessageSummary]="''"
    [invalidFileSizeMessageDetail]="invalidFileSizeMessageDetail"
    [invalidFileTypeMessageSummary]="invalidFileTypeMessageSummary"
    [invalidFileTypeMessageDetail]="invalidFileTypeMessageDetail" 
    [chooseLabel]="chooseLabel" [chooseIcon]="''" auto="true" customUpload="true"
    (uploadHandler)="uploadFilesAnexos($event)" mode="basic" (onSelect)="onSelect($event)">
  </p-fileUpload>
  <div *ngIf="myFiles && myFiles.length>0">
    <div class="col-12 card my-2 p-4 file-detail" *ngFor="let file of myFiles; index as i">
      <i class="icon-pdf font-thumb ml-2"></i>
      <span class="file-detail__info">
        <div class="ml-2">
          <div class="file-detail__title" *ngIf="!file.numeroRadicado"><strong>{{file.name}}</strong></div>
          <div class="file-detail__title file-detail__link" *ngIf="file.numeroRadicado" (click)="onClickDownloadFile(file?.file, file?.name, file?.type)"><strong>{{file.name}}</strong></div>
          <div *ngIf="showDetailInfo">
            <div *ngIf="file.numeroRadicado">{{'cargadorArchivos.numeroRadicado' | translate}} : <strong>{{ file.numeroRadicado}}</strong></div>
            <div>{{'cargadorArchivos.fechaDocumento' | translate}} : <strong>{{ fechaDocumento ? fechaDocumento : (file?.fechaDocumento ? file?.fechaDocumento : file?.lastModified | date:'dd/MM/yyyy')}}</strong></div>
            <div>{{'cargadorArchivos.totalFolios' | translate}} : <strong>{{file.numeroFolios ? file.numeroFolios : totalFolios}}</strong></div>
          </div>
        </div>
      </span>
      <span class="file-detail__delete" (click)="deleteFile(file, i)" *ngIf="editable === true">
        <img src="assets/images/close.svg" alt="close">
      </span>
    </div>
  </div>
  <div class="form-error" aria-live="assertive">
    <div *ngIf="invalidFileSize === true">
      <span style="color: red; font-size: 0.8em;">{{buildInvalidFileSizeMessage()}}</span>
    </div>
  </div>
  <div class="form-error" aria-live="assertive">
    <div *ngIf="invalidFileEmpty === true && required === true">
      <span style="color: red; font-size: 0.8em;">{{buildInvalidFileEmptyMessage()}}</span>
    </div>
  </div>
  <!-- Mensaje de alerta cuando el nombre del archivo supera los 60 caracteres -->
  <app-modal-error #modalMsgAlert>
  </app-modal-error>
</div>
