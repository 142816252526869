<p-table [columns]="columnas" [value]="datos"
  [responsive]="true" tableStyleClass="dynamic-table"
  (onPage)="changePage($event)"
  [paginator]="paginator"
  [rows]="rows" [(first)]="first">
    <ng-template pTemplate="header" let-columns>
        <tr>            
            <th *ngFor="let col of columns" [ngStyle]="col.hidden === true && {'display': 'none'}">
                <span>{{col.header}}</span>
            </th>
            <th *ngIf="actions" [ngClass]="styleActions">
              <span>{{ nombreCabeceraActions }}</span>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>          
            <td *ngFor="let col of columns" [ngSwitch]="col.type" [ngStyle]="col.hidden === true && {'display': 'none'}">
              <span class="ui-column-title">{{col.header}}</span>
              <span *ngSwitchCase="'date'">{{ rowData[col.field] | date:formatDate }}</span>
              <span *ngSwitchCase="'hour'">{{ rowData[col.field] | date:"HH:mm" }}</span>
              <span [ngClass]="paintSemaforo(rowData[col.field])" *ngSwitchCase="'semaforo'">{{ rowData[col.field] }}</span>
              <span (click)="cargarInformacion(col.type)" class="type-link-style" *ngSwitchCase="'link'">{{ rowData[col.field] }}</span>
              <span *ngSwitchCase="undefined ">
                  {{ rowData[col.field] }}
              </span>
            </td>
            <td>
              <span class="ui-column-title">{{ nombreCabeceraActions }}</span>
             <!--  <span *ngFor="let x of actions" class="icono-action" (click)="sendActionHandler(x.id, rowData[x?.nameAttributeModel])">
                <i class="{{ x?.icon }} icono-svg" title="{{ x?.iconTitle }}"></i>
              </span> -->
              <span *ngFor="let x of actions" class="icono-action">
                <span *ngIf="x.id === 'ver'">
                  <span (click)="sendActionHandler(x.id, rowData[x?.nameAttributeModel])">
                    <i class="{{ x?.icon }} icono-svg" title="{{ x?.iconTitle }}"></i>
                  </span>
                </span>
                <span *ngIf="x.id === 'eliminar'">
                  <span *ngIf="rowData['habilitado'] == 1" (click)="sendActionHandler(x.id, rowData[x?.nameAttributeModel])">
                    <i class="{{ x?.icon }} icono-svg" title="{{ x?.iconTitle }}"></i>
                  </span>
                </span>
              </span>
            </td>
        </tr>
    </ng-template>
</p-table>

