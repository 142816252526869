import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {InstitutionalLogosComponent} from './components/institutional-logos/institutional-logos.component';
import {SearchBienesComponent} from './components/search-bienes/search-bienes.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MenuComponent } from './components/menu/menu.component';
// import {ErrorComponent} from './components/error/error.component';
import {
  ConfirmDialogModule,
  DialogModule,
  FileUploadModule, GalleriaModule,
  InputNumberModule,
  MenuModule,
  RatingModule,
  SidebarModule,
  TableModule,
  ToastModule,
  ToolbarModule,
  DataViewModule
} from 'primeng';
import {FileComponent} from './components/file/file.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalErrorComponent } from './components/modal-error/modal-error.component';
import { FileUploadAnexoComponent } from './components/file-upload-anexo/file-upload-anexo.component';
import { CustomCalendarComponent } from './components/custom-calendar/custom-calendar.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { TablaDinamicaAutorizacionesComponent } from './components/tabla-dinamica-autorizaciones/tabla-dinamica-autorizaciones.component';
import { ModalSuccessComponent } from './components/modal-success/modal-success.component';
import { PostulacionesListComponent } from './components/postulaciones-list/postulaciones-list.component';
import { AudienciasListComponent } from './components/audiencias-list/audiencias-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import { CustomModalComponent } from './components/custom-modal/custom-modal.component';
import { RecaptchaComponent } from './components/recaptcha/recaptcha.component';
import { UserManualComponent } from './components/user-manual/user-manual.component';
import { footerInfoComponent } from './components/footer-info/footer-info.component';
import { CustomTranslateModule } from '../translate/translate.module';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    InstitutionalLogosComponent,
    SearchBienesComponent,
    MenuComponent,
    FileComponent,
    SpinnerComponent,
    ModalErrorComponent,
    FileUploadAnexoComponent,
    CustomCalendarComponent,
    DynamicTableComponent,
    TablaDinamicaAutorizacionesComponent,
    ModalSuccessComponent,
    PostulacionesListComponent,
    AudienciasListComponent,
    SideMenuComponent,
    CustomModalComponent,
    RecaptchaComponent,
    UserManualComponent,
    footerInfoComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    InstitutionalLogosComponent,
    SearchBienesComponent,
    MenuComponent,
    FileComponent,
    SpinnerComponent,
    ModalErrorComponent,
    FileUploadAnexoComponent,
    CustomCalendarComponent,
    DynamicTableComponent,
    TablaDinamicaAutorizacionesComponent,
    ModalSuccessComponent,
    PostulacionesListComponent,
    AudienciasListComponent,
    GalleriaModule,
    SideMenuComponent,
    CustomModalComponent,
    RecaptchaComponent,
    UserManualComponent,
    footerInfoComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    NgbModule,
    CalendarModule,
    SidebarModule,
    MenuModule,
    FileUploadModule,
    TableModule,
    ToolbarModule,
    ToastModule,
    RatingModule,
    DialogModule,
    InputNumberModule,
    ConfirmDialogModule,
    GalleriaModule,
    NgxSpinnerModule,
    DataViewModule,
    NgSelectModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    CustomTranslateModule
  ]
})
export class SharedModule { }
