import {Bien} from '../../protected/funcionario/model/bien';

export class ImageModel {

  id: string;
  idImagen: number;
  idRevBien: Bien;
  nroRadicado: number;
  urlStorageImagen: string;
  nombreImagen: string;
  rutaImagen: string;
  imagenes: ImageModel[];
  previewImageSrc?;
  thumbnailImageSrc?;

}
