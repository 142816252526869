<!-- Modal de success -->
<p-dialog class="custom-modal"
  [(visible)]="viewModalMessage"
  [styleClass]="'custom-dialog'"
  [closable]="false"
  [showHeader]="false"
  [resizable]="false"
  [modal]="true"
  [blockScroll]="true"
  [position]="'top'"
  [focusOnShow]="false"
  [baseZIndex]="10000">

  <div class="row p-4">
    <div class="col-12">

      <div class="row">
        <div class="col-12 text-center">
          <img [src]="icono" width="80">
        </div>
      </div>

      <div class="row mt-4 mx-2">
        <div class="col-12 mb-4 text-center">
          <h3 class="titulo-error">{{ titleModal | translate }}</h3>
          <div class="container-messaje" *ngIf="errors">
            <ul>
              <li style="color: red;" *ngFor="let error of errors">{{error}}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-12 text-center">
        <button type="button" (click)="complete()" class="btn btn-success">{{ nameBtnModal | translate }}</button>
      </div>

    </div>
  </div>
</p-dialog>
