import { Component, OnInit, OnChanges, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PdfService } from 'src/app/protected/services/comun/pdf.service';
import { MenuService } from 'src/app/protected/services/menu/menu.service';
import { LanguageService } from '../../../core/services/language/language.service';
import { ModalErrorComponent } from '../modal-error/modal-error.component';
import { ModalSuccessComponent } from '../modal-success/modal-success.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  readonly languages = [
    { value: 'es', label: 'Español'},
    { value: 'en', label: 'English'},
  ];
  public activeLang = 'es';
  public enlacePdf;
  //variables que almacenan la imagenes
  fileImagenHeader: string = "";
    /** Atributos modal de error */
  @ViewChild(ModalErrorComponent) modalError: ModalErrorComponent;
  @ViewChild('modalSuccess') modalSuccess: ModalSuccessComponent;

  constructor(public customTranslate: LanguageService, public translate: TranslateService, 
              public menuService : MenuService,
              public pdfService : PdfService,
    private sanitizer: DomSanitizer) {
    translate.setDefaultLang('es');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/es|en/) ? browserLang : 'es');
  }

  ngOnInit(): void {
    this.menuService.getImagenesYLogosPorParametros("IMAGEN_REMATES_ACTUALIZADOS", "logo_header").subscribe((data) => {
      this.fileImagenHeader = data;
    });
  }

  public changeLanguage(lang): void {
    this.activeLang = lang;
    this.customTranslate.setLang(lang);
  }

  private urlSeguro(value){
    return this.sanitizer.bypassSecurityTrustUrl(value);
  }

  public compruabeLenguage(){
    let compruebaVariable;
    if(this.activeLang === 'es'){
      compruebaVariable = 'manual_pdf_es';
    } else if(this.activeLang === 'en'){
      compruebaVariable = 'manual_pdf_en';
    }
    this.menuService.getManualPdf('MANUAL_PDF', compruebaVariable).subscribe(async resp=>{
      if(resp != null){
        this.enlacePdf = resp[1];
        let mensaje = await this.retorna('mensajes.INC2024000117.exitoso');
        this.modalSuccess.titleModal = mensaje;
        this.modalSuccess.viewModalMessage = true;
        this.modalSuccess.nameBtnModal = this.translate.instant('authentication.salir');
        setTimeout(() =>{
          this.mostrarPDF();
        }, 1000);
      }
    }, error=>{
      this.showMessageErrorPopup(this.translate.instant('mensajes.INC2024000117.error'));
    }, () =>{
    });
  }

  public abrirPdf(){
    // Ingresa
    const abrir = this.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      this.sanitizer.bypassSecurityTrustResourceUrl('https://riuma.uma.es/xmlui/bitstream/handle/10630/11484/Carre%F1o%20Villalba_TFG.pdf;jsessionid=3FBF7D6953C41247A596C5429108793F?sequence=1')
    );
    //console.log(abrir);
    window.open(abrir);
    //window.open(this.enlacePdf, '_blank');
  }

  public mostrarPDF() {
    const contentType = 'application/pdf';
    const blob = this.pdfService.base64ToPDF(this.enlacePdf, contentType);
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }
   /**
   * Retorna el valor de una llave
   * @param llave
   */
   async retorna(llave: string) {
    let valor = await this.translate.get(llave).toPromise();
    return valor;
  }
  
   /**
   * Muestra mensaje de error en el componente modal
   */
   private showMessageErrorPopup(mensaje = '') {
    this.modalError.titulo = 'Error';
    this.modalError.mensaje = mensaje;
    this.modalError.data = undefined;
    this.modalError.viewModalError = true;
  }
  
}
