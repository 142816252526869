import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../core/services/language/language.service';

@Pipe({
  name: 'translateUtils'
})
export class TranslateUtilsPipe implements PipeTransform {

  constructor(
    private translate: TranslateService,
    private lang: LanguageService) {
    this.translate.setDefaultLang(this.lang.browserLanguage());
  }

  transform(value: any, option?: any): any {
    if (option === null || option === undefined || option === '') {
      return value;
    } else {
      if (option === 'estadoAudiencia') {
        return this.traducirEstadoAudiencia(value);
      } else if (option === 'estadoValidacionDocumento') {
        return this.traducirEstadoValidacionDocumento(value);
      } else if (option === 'estadoRegistro') {
        return this.traducirEstadoRegistro(value);
      } else {
        return value;
      }
    }
  }

  /**
  * Traducir los estados de las audiencias
  * @param rowData
  * @param field
  */
  public traducirEstadoAudiencia(value) {
    if (value === 'Finalizada') {
      return this.translate.instant('estados.audiencias.finalizada');
    } else if (value === 'En curso') {
      return this.translate.instant('estados.audiencias.enCurso');
    } else if (value === 'Pendiente') {
      return this.translate.instant('estados.audiencias.pendiente');
    } else if (value === 'Cancelada') {
      return this.translate.instant('estados.audiencias.cancelada');
    } else if (value === 'Reanudada') {
      return this.translate.instant('estados.audiencias.reanudada');
    } else if (value === 'Desierta') {
      return this.translate.instant('estados.audiencias.desierta');
    } else if (value === 'Inválida') {
      return this.translate.instant('estados.audiencias.invalidada');
    } else if (value === 'Suspendida') {
      return this.translate.instant('estados.audiencias.suspendida');
    }
    return value;
  }

  /**
   * Traduce los estados de los documentos
   * @param rowData
   * @param field
   * @returns
   */
  public traducirEstadoValidacionDocumento(value) {
    if (value === 'Cumple' || value === 'CUMPLE' || value === '38' || value === 38) {
      return this.translate.instant('estados.documentos.cumple');
    } else if (value === 'No cumple' || value === 'NO CUMPLE' || value === '37'  || value === 37) {
      return this.translate.instant('estados.documentos.noCumple');
    } else if (value === 'Pendiente' || value === 'PENDIENTE' || value === '36'  || value === 36) {
      return this.translate.instant('estados.documentos.pendiente');
    }
    return value;
  }

  /**
   * Traducir los estados de las audiencias
   * @param rowData
   * @param field
   */
  public traducirEstadoRegistro(value) {
    if (value === 'Finalizado') {
      return this.translate.instant('estados.registro.finalizado');
    } else if (value === 'Capturado') {
      return this.translate.instant('estados.registro.capturado');
    }
    return value;
  }


}
